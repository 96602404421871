import React from 'react';
import { Presenter } from './Presenter';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';

type Props = {
  promotionSpot: NonNullable<PromotionSpotsState>[number];
};

const Container: React.VFC<Props> = ({ promotionSpot }) => {
  return <Presenter promotionSpot={promotionSpot} />;
};

export { Container as Promotion };
