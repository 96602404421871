import { gql } from '@apollo/client';
import { FRAGMENT_POINT_WALLET } from '/@/api/graphql/internalApi/fragments/pointWallet.gql';

export const getPointWalletGql = gql`
  ${FRAGMENT_POINT_WALLET}
  query GetPointWallet {
    pointWallet {
      ...PointWalletFields
    }
  }
`;
