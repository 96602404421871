import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import exchangeImage from '/@/assets/shared/lp/aboutSection/neo-signin-about-gift-wallet.png';
import { LpTypes, LP_TYPES } from '../types';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  backgroundColor: theme.palette.background.contrast,
  borderRadius: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  whiteSpace: 'pre-wrap',
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
}));
const StyledExchangeImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(3),
}));

type Props = {
  children?: React.ReactNode;
  lpType: LpTypes;
};
const Presenter: React.VFC<Props> = ({ children, lpType }) => (
  <StyledContainer data-testid="signInPageAboutSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow" textAlign="center">
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            {lpType === LP_TYPES.LP_CHARGE_SERIAL_CODE
              ? 'もっとお得な使い方\nえらべるPayをポイントに移行すると'
              : 'ポイント移行をすると'}
          </BaseTypography>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            まとめてポイントが使える
          </BaseTypography>
        </StyledTitleContainer>
      </Grid>
      <Grid size="grow">
        <StyledDescriptionContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            ポイントを移行すると、移行したポイントに加えてgift wallet
            に貯蓄済みのポイントも合わせて利用することができます。
          </BaseTypography>
        </StyledDescriptionContainer>
      </Grid>
      <Grid size="grow">
        <StyledExchangeImage src={exchangeImage} alt="exchangeImage" />
      </Grid>
      {children && <Grid size="grow">{children}</Grid>}
    </Grid>
  </StyledContainer>
);

export { Presenter };
