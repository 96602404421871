import { combineReducers, Reducer } from 'redux';
import { createOauthSessionTypes } from '/@/store/api/mutations/createOauthSession';
import { getSavingGifteeContentTypes } from '/@/store/api/queries/getSavingGifteeContent';
import type { RootAction } from '/@/store/actions';
import type { GetGifteeContentQuery } from '/@/api/graphql/publicApi/types';

type authorizationUrlState = string | null;
type SavingGifteeContentState = GetGifteeContentQuery['gifteeContent'] | null;
type SignInPageState = {
  authorizationUrl: authorizationUrlState;
  savingGifteeContent: SavingGifteeContentState;
};

const authorizationUrl: Reducer<authorizationUrlState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case createOauthSessionTypes.CREATE_OAUTH_SESSION_SUCCESS:
      return action.oauthSession.authorizationUrl;
    default:
      return state;
  }
};

const savingGifteeContent: Reducer<SavingGifteeContentState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getSavingGifteeContentTypes.GET_SAVING_GIFTEE_CONTENT_SUCCESS:
      return action.savingGifteeContent;
    default:
      return state;
  }
};

const signInPage = combineReducers({
  authorizationUrl,
  savingGifteeContent,
});

export { signInPage };
export type { SignInPageState };
