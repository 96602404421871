import { types } from './types';
import { ApiError } from '/@/api/graphql/client';
import type { GetFrequentlyAskedQuestionsGqlQuery } from '/@/api/graphql/publicApi/types';

export const getFrequentlyAskedQuestionsStart = () =>
  ({
    type: types.GET_FREQUENTLY_ASKED_QUESTIONS_START,
  }) as const;

export const getFrequentlyAskedQuestionsSuccess = (
  frequentlyAskedQuesgtions: GetFrequentlyAskedQuestionsGqlQuery['frequentlyAskedQuestions'],
) =>
  ({
    type: types.GET_FREQUENTLY_ASKED_QUESTIONS_SUCCESS,
    frequentlyAskedQuesgtions,
  }) as const;

export const getFrequentlyAskedQuestionsFailure = (error: ApiError) =>
  ({
    type: types.GET_FREQUENTLY_ASKED_QUESTIONS_FAILURE,
    error,
  }) as const;
