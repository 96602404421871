import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import { PATHS } from '/@/routes/paths';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';
import pointBackImage from '/@/assets/page/lpPage/campaignLP/campaign-lp-hero-point-back.png';
import presentBoxImage from '/@/assets/page/lpPage/campaignLP/campaign-lp-hero-present-box.png';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 3),
}));
const StyledMainTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '1',
  textAlign: 'center',
});
const StyledPointBackImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '85%',
  margin: `${theme.spacing(3)} auto 0`,
}));
const StyledPresentBoxImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '85%',
  margin: `${theme.spacing(2)} auto 0`,
}));
const StyledLoginButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));
const StyledConvertToPointNote = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  display: 'inline-block',
  width: '100%',
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontSize: '0.75rem',
}));

type Props = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <StyledContainer data-testid="signInPageHeroSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledMainTitle variant="h1">
          今なら、gift Wallet でギフト交換で
        </StyledMainTitle>
      </Grid>
      <Grid size="grow">
        <StyledPointBackImage
          src={pointBackImage}
          alt="giftWalletPointBackImage"
        />
        <StyledPresentBoxImage
          src={presentBoxImage}
          alt="giftWalletPresentBoxImage"
        />
      </Grid>
      <Grid size="grow">
        <StyledLoginButtonContainer>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={null}
            flow={PATHS.LP_CAMPAIGN_TIKTOK}
          >
            ギフトをポイントに移行する
          </LoginButton>
          <StyledConvertToPointNote variant="caption">
            ※ポイントは残高全てが移行されます。ご注意ください。
          </StyledConvertToPointNote>
        </StyledLoginButtonContainer>
      </Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
