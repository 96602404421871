import { gql } from '@apollo/client';

export const getSavingGifteeContentGql = gql`
  query GetGifteeContent($url: String!, $origin: OriginEnum!) {
    gifteeContent(url: $url, origin: $origin) {
      myGifts {
        contentName
        imageUrl
        giftUrl
        giftType
        expiredAt
        remainingPoint
      }
    }
  }
`;
