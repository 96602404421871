import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { BaseDivider } from '../../shared/divider/Index';

export const FOOTER_HEIGHT = 138;
const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  paddingBottom: theme.spacing(4),
  margin: '0 auto',
  backgroundColor: theme.palette.common.white,
  width: '100%',
  maxWidth: '600px',
  height: `${FOOTER_HEIGHT}px`,
  minHeight: `${FOOTER_HEIGHT}px`,
}));
const StyledItemContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
const StyledLeftItemContainer = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1.5',
  '& > * + *': {
    marginTop: theme.spacing(1.25),
  },
}));
const StyledATag = styled('a')({
  textDecoration: 'none',
  display: 'block',
});
const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'block',
});
const StyledLinkTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledCopyright = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: '0.75rem',
  color: theme.palette.grey[700],
}));
const StyledDividerContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledFooter>
    <StyledDividerContainer>
      <BaseDivider />
    </StyledDividerContainer>
    <StyledItemContainer>
      <StyledLeftItemContainer>
        <StyledATag
          href="https://giftee.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkTitle variant="caption">運営会社</StyledLinkTitle>
        </StyledATag>
        <StyledLink to={PATHS.FREQUENTLY_ASKED_QUESTIONS}>
          <StyledLinkTitle variant="caption">よくあるご質問</StyledLinkTitle>
        </StyledLink>
        <StyledLink to={PATHS.TERMS_OF_SERVICE}>
          <StyledLinkTitle variant="caption">サービス利用規約</StyledLinkTitle>
        </StyledLink>
      </StyledLeftItemContainer>
      <StyledCopyright>©︎giftee, inc.</StyledCopyright>
    </StyledItemContainer>
  </StyledFooter>
);

export { Presenter };
