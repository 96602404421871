import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Link, Box } from '@mui/material';
import { styled } from '@mui/system';
import { HeroSection } from './heroSection/Index';
import { LoginSection } from './loginSection/Index';
import { AboutSection } from './aboutSection/Index';
import { FeatureSection } from './featureSection/Index';
import { UsageSection } from './usageSection/Index';
import { SummarySection } from './summarySection/Index';
import { Footer } from '/@/components/templates/footer/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { Sticky } from '/@/components/shared/sticky/Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '../../shared/typography/Index';
import { PATHS } from '/@/routes/paths';
import { theme } from '/@/utils/theme';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledSection = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2),
  paddingTop: theme.spacing(3),
  '& + &': {
    paddingTop: theme.spacing(4),
  },
}));
const StyledStickyContainer = styled('div')({
  display: 'flex',
});
const StyledStickyNoteContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
  color: theme.palette.secondary.dark,
}));
const StyledStickyLoginButtonContainer = styled('div')({
  minWidth: '200px',
});

type Props = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
  errorMessage: string | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
  errorMessage,
}) => (
  <Grid container direction="column" alignContent="center">
    <StyledContainer maxWidth="sm">
      <StyledSection>
        <HeroSection />
        <LoginSection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
          isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
          serialCodeEndAt={serialCodeEndAt}
          errorMessage={errorMessage}
        />
      </StyledSection>
      <StyledSection>
        <AboutSection />
      </StyledSection>
      <StyledSection>
        <FeatureSection />
      </StyledSection>
      <StyledSection>
        <UsageSection />
      </StyledSection>
      <StyledSection>
        <SummarySection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        />
      </StyledSection>
      <Box mt={4} />
      <Footer />
      <Sticky>
        <StyledStickyContainer>
          <StyledStickyNoteContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
              <Link
                component={RouterLink}
                to={PATHS.TERMS_OF_SERVICE}
                style={{
                  color: theme.palette.secondary.dark,
                  textDecorationColor: theme.palette.secondary.dark,
                }}
              >
                サービス利用規約
              </Link>
              に同意したことになります
            </BaseTypography>
          </StyledStickyNoteContainer>
          <StyledStickyLoginButtonContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={null}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={serialCode}
              isDisplayButtonOnly
            >
              {serialCode
                ? 'ポイントチャージ'
                : url && origin
                  ? 'ギフト保存'
                  : 'ログイン'}
            </LoginButton>
          </StyledStickyLoginButtonContainer>
        </StyledStickyContainer>
      </Sticky>
    </StyledContainer>
  </Grid>
);

export { Presenter };
