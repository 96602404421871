export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

/** Autogenerated input type of CreateOauthSession */
export type CreateOauthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationOption?: InputMaybe<DestinationOptionInput>;
  giftSavingOption?: InputMaybe<GiftSavingOptionInput>;
  pointSerialOption?: InputMaybe<PointSerialOptionInput>;
  userAuthenticatorUid: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOauthSession. */
export type CreateOauthSessionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  oauthSession: CreatedOauthSession;
};

/** Autogenerated input type of CreateUserRegistrationSession */
export type CreateUserRegistrationSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['String']['input'];
  destinationOption?: InputMaybe<DestinationOptionInput>;
  email: Scalars['String']['input'];
  genderCode: Scalars['String']['input'];
  giftSavingOption?: InputMaybe<GiftSavingOptionInput>;
  month: Scalars['String']['input'];
  password: Scalars['String']['input'];
  pointSerialOption?: InputMaybe<PointSerialOptionInput>;
  prefectureCode: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

/** Autogenerated return type of CreateUserRegistrationSession. */
export type CreateUserRegistrationSessionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreatedOauthSession = SessionTypeInterface & {
  authorizationUrl: Scalars['String']['output'];
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

export enum DestinationEnum {
  Brands = 'brands',
  Gifts = 'gifts',
  Inquiry = 'inquiry',
  PointLogs = 'point_logs',
  Promotions = 'promotions',
}

export type DestinationOption = {
  name: DestinationEnum;
};

export type DestinationOptionInput = {
  name: DestinationEnum;
};

export type Error = {
  extensions: ErrorExtensions;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export enum ErrorCodeEnumType {
  CampaignReachedLimitation = 'CAMPAIGN_REACHED_LIMITATION',
  DuplicatedGifteeContent = 'DUPLICATED_GIFTEE_CONTENT',
  ExchangeError = 'EXCHANGE_ERROR',
  ItemNotAvailable = 'ITEM_NOT_AVAILABLE',
  LineAuthenticationFailed = 'LINE_AUTHENTICATION_FAILED',
  PointBackCampaignNotAvailable = 'POINT_BACK_CAMPAIGN_NOT_AVAILABLE',
  PointWalletBalanceShortage = 'POINT_WALLET_BALANCE_SHORTAGE',
  SerialCodeNotFound = 'SERIAL_CODE_NOT_FOUND',
  SerialCodeOutOfPeriod = 'SERIAL_CODE_OUT_OF_PERIOD',
  SerialCodeUsed = 'SERIAL_CODE_USED',
}

export type ErrorExtensions = {
  code: Maybe<ErrorCodeEnumType>;
  status: Scalars['Int']['output'];
};

export type FrequentlyAskedQuestion = {
  answer: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GiftSavingOption = {
  convertToPoint: Scalars['Boolean']['output'];
  origin: OriginEnum;
  referer: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GiftSavingOptionInput = {
  convertToPoint?: InputMaybe<Scalars['Boolean']['input']>;
  origin: OriginEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum GiftTypesEnum {
  GifteeBox = 'giftee_box',
  SimpleGift = 'simple_gift',
  SingleSelect = 'single_select',
}

export type GifteeContent = {
  myGifts: Array<MyGift>;
};

export type Mutation = {
  createOauthSession: CreateOauthSessionPayload;
  createUserRegistrationSession: CreateUserRegistrationSessionPayload;
  notifyInquiry: NotifyInquiryPayload;
  signInOrSignUp: SignInOrSignUpPayload;
  verifyEmail: VerifyEmailPayload;
};

export type MutationCreateOauthSessionArgs = {
  input: CreateOauthSessionInput;
};

export type MutationCreateUserRegistrationSessionArgs = {
  input: CreateUserRegistrationSessionInput;
};

export type MutationNotifyInquiryArgs = {
  input: NotifyInquiryInput;
};

export type MutationSignInOrSignUpArgs = {
  input: SignInOrSignUpInput;
};

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type MyGift = MyGiftInterface & {
  contentName: Scalars['String']['output'];
  /** 有効期限 */
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  giftType: GiftTypesEnum;
  giftUrl: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  /** remaining_point はキャッシュを返す。最新のデータを取得したい場合はmutationのrefreshMyGiftから取得すること。キャッシュデータは日次jobで更新しています。 */
  remainingPoint: Scalars['Int']['output'];
};

export type MyGiftInterface = {
  contentName: Scalars['String']['output'];
  /** 有効期限 */
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  giftType: GiftTypesEnum;
  giftUrl: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  /** remaining_point はキャッシュを返す。最新のデータを取得したい場合はmutationのrefreshMyGiftから取得すること。キャッシュデータは日次jobで更新しています。 */
  remainingPoint: Scalars['Int']['output'];
};

/** Autogenerated input type of NotifyInquiry */
export type NotifyInquiryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};

/** Autogenerated return type of NotifyInquiry. */
export type NotifyInquiryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type OauthSession = SessionTypeInterface & {
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

export enum OriginEnum {
  G4b = 'g4b',
  Gcp = 'gcp',
}

export type PointSerialOption = {
  serialCode: Scalars['String']['output'];
};

export type PointSerialOptionInput = {
  serialCode: Scalars['String']['input'];
};

export type Prefecture = {
  name: Scalars['String']['output'];
};

export type PublicAsset = {
  uid: Scalars['String']['output'];
  uploadedAssetUrl: Scalars['String']['output'];
};

export type Query = {
  existSameConfirmedEmail: Scalars['Boolean']['output'];
  frequentlyAskedQuestions: Array<FrequentlyAskedQuestion>;
  gifteeContent: GifteeContent;
  oauthSession: OauthSession;
  prefectures: Array<Prefecture>;
  publicAssets: Array<PublicAsset>;
  userAuthenticators: Array<UserAuthenticator>;
  userRegistrationSession: UserRegistrationSession;
};

export type QueryExistSameConfirmedEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryFrequentlyAskedQuestionsArgs = {
  serviceName: ServiceNameEnum;
};

export type QueryGifteeContentArgs = {
  origin: OriginEnum;
  uid?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOauthSessionArgs = {
  state: Scalars['String']['input'];
};

export type QueryPublicAssetsArgs = {
  uidIn: Array<Scalars['String']['input']>;
};

export type QueryUserRegistrationSessionArgs = {
  uid: Scalars['String']['input'];
};

export enum ServiceNameEnum {
  Reward = 'reward',
  Wallet = 'wallet',
}

export type SessionTypeInterface = {
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

/** Autogenerated input type of SignInOrSignUp */
export type SignInOrSignUpInput = {
  authorizationCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};

/** Autogenerated return type of SignInOrSignUp. */
export type SignInOrSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  oauthSession: Maybe<OauthSession>;
  session: Maybe<UserSession>;
};

export type UserAuthenticator = {
  label: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type UserRegistrationSession = SessionTypeInterface & {
  destinationOption: Maybe<DestinationOption>;
  giftSavingOption: Maybe<GiftSavingOption>;
  pointSerialOption: Maybe<PointSerialOption>;
};

export type UserSession = {
  expiredAt: Scalars['ISO8601DateTime']['output'];
  token: Scalars['String']['output'];
};

/** Autogenerated input type of VerifyEmail */
export type VerifyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmationToken: Scalars['String']['input'];
  registrationSessionUid: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyEmail. */
export type VerifyEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  loginSession: Maybe<UserSession>;
  registrationSession: Maybe<UserRegistrationSession>;
};

export type CreateOauthSessionMutationVariables = Exact<{
  createOauthSessionInput: CreateOauthSessionInput;
}>;

export type CreateOauthSessionMutation = {
  createOauthSession: { oauthSession: { authorizationUrl: string } };
};

export type NotifyInquiryMutationVariables = Exact<{
  input: NotifyInquiryInput;
}>;

export type NotifyInquiryMutation = {
  notifyInquiry: {
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type SignInOrSignUpMutationVariables = Exact<{
  signInOrSignUpInput: SignInOrSignUpInput;
}>;

export type SignInOrSignUpMutation = {
  signInOrSignUp: {
    session: { token: string; expiredAt: string } | null;
    oauthSession: {
      giftSavingOption: {
        url: string;
        origin: OriginEnum;
        referer: string | null;
      } | null;
      destinationOption: { name: DestinationEnum } | null;
      pointSerialOption: { serialCode: string } | null;
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type GetFrequentlyAskedQuestionsGqlQueryVariables = Exact<{
  serviceName: ServiceNameEnum;
}>;

export type GetFrequentlyAskedQuestionsGqlQuery = {
  frequentlyAskedQuestions: Array<{ title: string; answer: string }>;
};

export type GetOauthSessionQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;

export type GetOauthSessionQuery = {
  oauthSession: {
    giftSavingOption: {
      url: string;
      origin: OriginEnum;
      referer: string | null;
      convertToPoint: boolean;
    } | null;
    destinationOption: { name: DestinationEnum } | null;
    pointSerialOption: { serialCode: string } | null;
  };
};

export type GetPrefecturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrefecturesQuery = { prefectures: Array<{ name: string }> };

export type GetPublicAssetsQueryVariables = Exact<{
  uidIn: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetPublicAssetsQuery = {
  publicAssets: Array<{ uid: string; uploadedAssetUrl: string }>;
};

export type GetGifteeContentQueryVariables = Exact<{
  url: Scalars['String']['input'];
  origin: OriginEnum;
}>;

export type GetGifteeContentQuery = {
  gifteeContent: {
    myGifts: Array<{
      contentName: string;
      imageUrl: string;
      giftUrl: string;
      giftType: GiftTypesEnum;
      expiredAt: string | null;
      remainingPoint: number;
    }>;
  };
};

export type GetUserAuthenticatorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserAuthenticatorsQuery = {
  userAuthenticators: Array<{ label: string; uid: string }>;
};
