import { gql } from '@apollo/client';
import { FRAGMENT_MY_GIFT } from './myGift.gql';

export const FRAGMENT_GIFTEE_CONTENT = gql`
  ${FRAGMENT_MY_GIFT}
  fragment GifteeContentFields on GifteeContent {
    urlCode
    myGifts {
      ...MyGiftFields
    }
  }
`;
