import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { POINT_FONT_SIZE, Points } from '../points/Index';
import { PointWalletFieldsFragment } from '/@/api/graphql/internalApi/types';

type PointWallet = PointWalletFieldsFragment | null;

export const POINT_SECTION_HEIGHT = 64;
export const POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT = 64;

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  height: `${POINT_SECTION_HEIGHT}px`,
  borderRadius: theme.spacing(),
  backgroundColor: theme.palette.background.contrast,
}));
const StyledHeading = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});
const StyledPointContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1), // MEMO: 表示位置調整用
}));

type Props = {
  pointWallet: PointWallet;
  benefitOfPointChargeModalVisible: boolean;
};

const Presenter: React.VFC<Props> = ({
  pointWallet,
  benefitOfPointChargeModalVisible,
}) => (
  <StyledContainer>
    <StyledHeading container alignItems="center">
      {pointWallet?.requirePointRefresh ? (
        <PartialLoader size={15} marginSize={1} />
      ) : (
        <StyledPointContainer>
          <Points points={pointWallet?.point} fontSize={POINT_FONT_SIZE.LG} />
        </StyledPointContainer>
      )}
      {benefitOfPointChargeModalVisible && (
        <BenefitOfPointChargeModalWithButton />
      )}
    </StyledHeading>
  </StyledContainer>
);

export { Presenter };
