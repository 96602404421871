import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import HowToUseImage from '/@/assets/shared/lp/howToUseSection/neo-signin-how-to-use-decoration.png';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  backgroundColor: theme.palette.background.contrast,
  borderRadius: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
  textAlign: 'left',
}));
const StyledHowToUseImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));
const StyledHowToUseImage = styled('img')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(0, 3),
  paddingTop: theme.spacing(3),
  width: '100%',
}));
const StyledDetailTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));
const StyledDetailNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  height: '26px',
  width: '26px',
  borderRadius: '2px 8px 8px 2px',
  margin: '0 auto',
  backgroundColor: '#1DABD6',
  textAlign: 'center',
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold',
}));
const StyledDetailTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));
const StyledDetailDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
}));

const Presenter: React.VFC<Record<string, never>> = () => {
  const details = [
    {
      number: 1,
      title: 'もらったギフトを一覧で管理できる',
      description:
        '「マイギフト」ページから確認できるので、ギフトの期限切れ、使い忘れを減らせます。',
    },
    {
      number: 2,
      title: '保存したチケットをすぐに表示できる',
      description:
        'gift walletのLINEアカウントを開いて、チケットをタップするだけ。',
    },
    {
      number: 3,
      title: 'ギフトはLINEから確認できる',
      description:
        'ギフトがgift walletに保存されると、LINEアカウントに通知されます。',
    },
  ];

  return (
    <StyledContainer data-testid="signInPageAboutSection">
      <Grid container direction="column" alignContent="center">
        <Grid textAlign="center">
          <StyledTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.HEADLINE}
              fontSize={FONT_SIZE.LG}
              isBold
            >
              gift wallet の利用方法
            </BaseTypography>
          </StyledTitleContainer>
          <StyledDescriptionContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              gift wallet は
              せっかくのギフトを使い忘れなく有効活用するためのお財布サービスです。
            </BaseTypography>
          </StyledDescriptionContainer>
          <StyledHowToUseImageContainer>
            <StyledHowToUseImage
              src={HowToUseImage}
              alt="gift wallet の利用方法"
            />
          </StyledHowToUseImageContainer>
        </Grid>

        {details.map((detail) => (
          <Grid key={detail.number}>
            <StyledDetailTextContainer>
              <StyledDetailNumber>{detail.number}</StyledDetailNumber>
              <StyledDetailTitleContainer>
                <BaseTypography
                  fontType={FONT_TYPE.HEADLINE}
                  fontSize={FONT_SIZE.MD}
                  isBold
                >
                  {detail.title}
                </BaseTypography>
              </StyledDetailTitleContainer>
              <StyledDetailDescriptionContainer>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.MD}
                >
                  {detail.description}
                </BaseTypography>
              </StyledDetailDescriptionContainer>
            </StyledDetailTextContainer>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

export { Presenter };
