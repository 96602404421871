import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { GifteeContent } from './gifteeContent/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ErrorMessage } from '/@/store/api/mutations/saveGifteeContent/selectors';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import giftWalletLogo from '/@/assets/shared/logo/gift-wallet-logo-with-circle.png';

const StyledSavedGiftsBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
}));
const StyledSavedGiftsTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));
const StyledLogoContainer = styled('div')({
  margin: '0 auto',
});
const StyledSavedGifts = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  minWidth: '100%',
  maxWidth: '100%',
}));

type Props = { duplicatedGifteeContentErrorMessage: ErrorMessage };
const Presenter: React.VFC<Props> = ({
  duplicatedGifteeContentErrorMessage,
}) => (
  <MainLayout>
    <StyledSavedGiftsBackground data-testid="page-giftsShowPage">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledLogoContainer>
          <img
            src={giftWalletLogo}
            alt="giftWalletLogo"
            width="60px"
            height="60px"
          />
        </StyledLogoContainer>
        <StyledSavedGiftsTitle>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            {duplicatedGifteeContentErrorMessage
              ? duplicatedGifteeContentErrorMessage
              : 'ギフトを保存しました'}
          </BaseTypography>
        </StyledSavedGiftsTitle>
        <StyledSavedGifts>
          <GifteeContent />
        </StyledSavedGifts>
      </Grid>
    </StyledSavedGiftsBackground>
    <ItemDetailModal />
  </MainLayout>
);

export { Presenter };
