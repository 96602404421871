import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import LogoImage from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import TopImage from '/@/assets/page/lpPage/lpChargeSerialCodePage/neo-lp-hero-top-image.png';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledContainer = styled('div')({
  width: '100%',
});
const StyledLogoImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledLogoImage = styled('img')({
  display: 'block',
  width: '200px',
  margin: '0 auto',
});
const StyledTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  color: theme.palette.secondary.dark,
}));
const StyledTopImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(2),
  paddingBottom: 0,
}));
const StyledTopImage = styled('img')({
  display: 'block',
  margin: 'auto',
  width: '100%',
});

type Props = {
  children: React.ReactNode;
};

const Presenter: React.VFC<Props> = ({ children }) => (
  <StyledContainer>
    <Grid container direction="column" alignContent="center">
      <Grid size="grow" textAlign="center">
        <StyledLogoImageContainer>
          <StyledLogoImage src={LogoImage} alt="gift Wallet Logo" />
        </StyledLogoImageContainer>
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.XL}
            isBold
          >
            gift wallet ポイントをチャージして
            <br />
            お得な特典をフル活用しよう！
          </BaseTypography>
        </StyledTitleContainer>
      </Grid>
      <Grid size="grow">
        <StyledTopImageContainer>
          <StyledTopImage src={TopImage} alt="gift Wallet Logo" />
        </StyledTopImageContainer>
      </Grid>
      <Grid size="grow">{children}</Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
