import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';

type StyledLabelProps = {
  isSmall: boolean;
  fontColor: string;
  backgroundColor: string;
};
const StyledLabel = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== 'isSmall' && prop !== 'fontColor' && prop !== 'backgroundColor',
})<StyledLabelProps>(({ isSmall, fontColor, backgroundColor, theme }) => ({
  padding: isSmall ? theme.spacing(0.25, 0.75) : theme.spacing(0.25, 1),
  height: isSmall ? theme.spacing(2) : theme.spacing(2.875),
  backgroundColor: backgroundColor,
  '& .MuiChip-label': {
    padding: '0',
    color: fontColor,
    fontSize: isSmall ? '0.625rem' : '0.75rem',
    lineHeight: isSmall ? '1' : '1.2',
    fontWeight: 'bold',
  },
}));

type Props = {
  labelText: string;
  labelSize: LabelSizeTypes;
  color: string;
  backgroundColor: string;
};

export type LabelSizeTypes = (typeof LABEL_SIZE)[keyof typeof LABEL_SIZE];

export const LABEL_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

const Presenter: React.VFC<Props> = ({
  labelText,
  labelSize,
  color,
  backgroundColor,
}) => (
  <StyledLabel
    label={`${labelText}`}
    isSmall={labelSize === 'small'}
    fontColor={color}
    backgroundColor={backgroundColor}
  />
);

export { Presenter };
