import { FetchResult } from '@apollo/client';
import { getFrequentlyAskedQuestionGql } from './index.gql';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import {
  GetFrequentlyAskedQuestionsGqlQuery,
  GetFrequentlyAskedQuestionsGqlQueryVariables,
} from '/@/api/graphql/publicApi/types';

type GetFrequentlyAskedQuestionsResponse =
  FetchResult<GetFrequentlyAskedQuestionsGqlQuery>;

const getFrequentlyAskedQuestions = (
  variables: GetFrequentlyAskedQuestionsGqlQueryVariables,
): Promise<GetFrequentlyAskedQuestionsResponse> =>
  publicApiClient.query({
    query: getFrequentlyAskedQuestionGql,
    variables,
  });

export { getFrequentlyAskedQuestions };

export type { GetFrequentlyAskedQuestionsResponse };
