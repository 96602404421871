import React from 'react';
import { styled } from '@mui/system';
import menuIcon from '/@/assets/templates/header/globalHeader/global-menu-icon.svg';
import { IconButton } from '@mui/material';
import { UnreadBadge } from '/@/components/shared/badge/unreadBadge/Index';

const StyledMenuIconButton = styled(IconButton)({
  padding: 0,
  height: '1.5rem',
});

const StyledMenuWrapper = styled('div')({
  position: 'relative',
  display: 'inline-block',
  height: '100%',
});

const StyledUnreadedBadgeWrapper = styled('div')({
  position: 'absolute',
  top: -14,
  right: -4,
});

type Props = {
  unreadBadgeVisible: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
};

const Presenter: React.VFC<Props> = ({
  unreadBadgeVisible,
  handleToggleMenu,
  handleClickGlobalMenu,
}) => (
  <StyledMenuIconButton
    color="primary"
    aria-label="menu-icon-button"
    onClick={() => {
      handleToggleMenu();
      handleClickGlobalMenu();
    }}
    data-gtm-click="templates-header-menuButton"
  >
    <StyledMenuWrapper>
      <img height={26} src={menuIcon} alt="メニューアイコン" />
      {unreadBadgeVisible && (
        <StyledUnreadedBadgeWrapper>
          <UnreadBadge />
        </StyledUnreadedBadgeWrapper>
      )}
    </StyledMenuWrapper>
  </StyledMenuIconButton>
);

export { Presenter };
