import React from 'react';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { MyGiftBase } from '/@/store/app/shared/types';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import checkboxCheckedIcon from '/@/assets/shared/icon/checkbox-checked-icon.svg';
import checkboxNotcheckedIcon from '/@/assets/shared/icon/checkbox-notchecked-icon.svg';
import { PATHS } from '/@/routes/paths';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledGroupedLabelContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));
const StyledEditableMyGiftFormContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledEditableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4, 2),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
}));
const StyledEditableMyGiftsNotFoundTextContainer = styled('div')({
  textAlign: 'center',
});
const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    width: '100%',
    margin: '0',
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
  '&.MuiFormControlLabel-label': {
    minWidth: '0',
    width: '100%',
    pointerEvents: 'none',
  },
}));

type Props = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getAvailableMyGiftsConnectionStatus: Status;
  handleEditingMyGift: (myGift: MyGiftBase, checked: boolean) => void;
  isSelectedMyGift: (myGift: MyGiftBase) => boolean;
};

const Presenter: React.VFC<Props> = ({
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getAvailableMyGiftsConnectionStatus,
  handleEditingMyGift,
  isSelectedMyGift,
}) => (
  <Grid>
    <div>
      {Object.entries(groupedAvailableMyGifts).length ? (
        Object.entries(groupedAvailableMyGifts).map(
          ([date, myGifts], index) => (
            <React.Fragment key={index}>
              <StyledGroupedLabelContainer>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.SM}
                >
                  {date}
                </BaseTypography>
              </StyledGroupedLabelContainer>
              <FormGroup>
                {myGifts?.map(
                  (myGift) =>
                    myGift && (
                      <React.Fragment key={myGift.urlCode}>
                        <BaseDivider />
                        <StyledEditableMyGiftFormContainer key={myGift.urlCode}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                checked={isSelectedMyGift(myGift)}
                                onChange={(_, checked) =>
                                  handleEditingMyGift(myGift, checked)
                                }
                                style={{ padding: 0, marginRight: '16px' }}
                                icon={
                                  <img
                                    src={checkboxNotcheckedIcon}
                                    alt="checkboxNotcheckedIcon"
                                  />
                                }
                                checkedIcon={
                                  <img
                                    src={checkboxCheckedIcon}
                                    alt="checkboxCheckedIcon"
                                  />
                                }
                              />
                            }
                            label={
                              <MyGift
                                myGift={myGift}
                                isHiddenArrowIcon
                                isUnclickable
                              />
                            }
                          />
                        </StyledEditableMyGiftFormContainer>
                      </React.Fragment>
                    ),
                )}
              </FormGroup>
            </React.Fragment>
          ),
        )
      ) : (
        <StyledEditableMyGiftsNotFoundContainer
          container
          justifyContent="center"
          direction="column"
        >
          <StyledEditableMyGiftsNotFoundTextContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              現在ご利用可能なギフトはありません。
            </BaseTypography>
          </StyledEditableMyGiftsNotFoundTextContainer>
          <StyledButtonContainer>
            <BaseButton component={Link} to={PATHS.HOME}>
              トップページへ戻る
            </BaseButton>
          </StyledButtonContainer>
        </StyledEditableMyGiftsNotFoundContainer>
      )}
    </div>
    {getAvailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
