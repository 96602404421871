import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    gifteeCoral: Palette['primary'];
  }
  interface PaletteOptions {
    gifteeCoral: PaletteOptions['primary'];
  }
  interface TypeBackground {
    hint: string;
    contrast: string;
    highContrast: string;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0C6993',
      dark: '#003F65',
      light: '#5097C4',
    },
    secondary: {
      dark: '#4B4B4B',
      main: '#6C7F87',
      light: '#F7F7F7',
    },
    success: {
      main: '#32CD32',
      light: '#F2FAF1',
    },
    warning: {
      main: '#EB7152',
    },
    error: {
      main: '#D32F2F',
      light: '#D93E17',
    },
    gifteeCoral: {
      main: '#F08B71',
    },
    background: {
      default: '#FFFFFF',
      hint: '#F6F7F8',
      contrast: '#E8F7FB',
      highContrast: '#5097C4',
    },
    common: {
      white: '#FFFFFF',
      black: '#232323',
    },
    grey: {
      100: '#F7F7F7',
      400: '#D9D9D9',
      700: '#777777',
      900: '#4E5B61',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Helvetica Neue',
      'Arial',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Sans',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
});
