import React, { useEffect, useRef } from 'react';
import { Presenter } from './Presenter';
import { OriginEnum, DestinationEnum } from '/@/api/graphql/publicApi/types';
import { useAppDispatch, useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';
import { createOauthSessionThunk } from '/@/store/api/mutations/createOauthSession';
import { getUserAuthenticatorsThunk } from '/@/store/api/queries/getUserAuthenticators';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { PATHS, PathValues } from '/@/routes/paths';
import { createOauthSessionOption } from '/@/utils/createOauthSessionOption';
import { useLocation } from 'react-router-dom';

export type LoginOption = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
  serialCode: string | null;
  flow?: PathValues;
  isDisplayNoteForPointConvert?: boolean;
  isDisplayButtonOnly?: boolean;
};

const getGAEventNameWithFlow = (flow: PathValues | undefined) => {
  switch (flow) {
    case PATHS.LP_CONVERT_TO_POINT:
      return 'handleLpConvertToPointButton';
    case PATHS.LP_CHARGE_SERIAL_CODE:
      return 'handleLpChargeSerialCodeButton';
    case PATHS.LP_MERGE_TO_POINT:
      return 'handleLpMergeToPointButton';
    case PATHS.LP_CAMPAIGN_TIKTOK:
      return 'handleLpCampaignTiktokButton';
    default:
      return 'handleLoginButton';
  }
};

type Props = LoginOption & {
  children: string;
};

const Container: React.VFC<Props> = ({
  convertToPoint,
  destination,
  serialCode,
  flow,
  isDisplayNoteForPointConvert = false,
  isDisplayButtonOnly = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { pushClickEvent } = useDataLayer();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const isPushedClickEventRef = useRef(false);

  const createOauthSessionApiStatus = useAppSelector(
    (state) => state.api.createOauthSession.status,
  );

  const authorizationUrl = useAppSelector(
    (state) => state.page.signInPage.authorizationUrl,
  );

  const userAuthenticators = useAppSelector(
    (state) => state.app.userAuthenticators,
  );

  const getUserAuthenticatorsStatus = useAppSelector(
    (state) => state.api.getUserAuthenticators.status,
  );

  const handleClick = () => {
    if (isPushedClickEventRef.current === false) {
      // ログインボタンクリック時のデータレイヤー送信
      pushClickEvent(
        {
          clickItem: getGAEventNameWithFlow(flow),
          customData: {
            clickItem: getGAEventNameWithFlow(flow),
            convertToPoint: convertToPoint,
            destination: destination,
            withSerialCode: serialCode ? true : false,
          },
        },
        true,
      );
      isPushedClickEventRef.current = true;
    }
    if (userAuthenticators && userAuthenticators.length) {
      // 足元UserAuthenticatorは一つしか登録せず運用する
      // 複数にするタイミングでログインのUIが変わるので一旦最初のものを固定で取得
      const userAuthenticator = userAuthenticators[0];
      const { giftSavingOption, destinationOption, pointSerialOption } =
        createOauthSessionOption(urlSearchParams);

      return dispatch(
        createOauthSessionThunk({
          userAuthenticatorUid: userAuthenticator.uid,
          giftSavingOption: giftSavingOption,
          destinationOption: destinationOption,
          pointSerialOption: pointSerialOption,
        }),
      ).catch(() => {});
    }
  };

  useEffect(() => {
    if (
      createOauthSessionApiStatus === STATUS.SUCCESS &&
      authorizationUrl !== null
    ) {
      location.href = authorizationUrl;
    }
  }, [createOauthSessionApiStatus, authorizationUrl]);

  useEffect(() => {
    if (getUserAuthenticatorsStatus === STATUS.IDLE) {
      dispatch(getUserAuthenticatorsThunk()).catch(() => {});
    }
  }, [dispatch, getUserAuthenticatorsStatus]);

  return (
    <Presenter
      onClick={handleClick}
      isDisplayNoteForPointConvert={isDisplayNoteForPointConvert}
      isDisplayButtonOnly={isDisplayButtonOnly}
    >
      {children}
    </Presenter>
  );
};

export { Container as LoginButton };
