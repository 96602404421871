import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import logo from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '../../shared/typography/Index';

const StyledContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(6),
}));
const StyledTitleContainer = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.secondary.dark,
  textAlign: 'center',
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.common.black,
  textAlign: 'center',
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledContainer data-testid="page-internalServerError">
      <img src={logo} alt="gift wallet logo" width="100%" height="26" />
      <StyledTitleContainer>
        <BaseTypography
          fontType={FONT_TYPE.HEADLINE}
          fontSize={FONT_SIZE.MD}
          isBold
        >
          システムエラーが発生しました
        </BaseTypography>
      </StyledTitleContainer>
      <StyledDescriptionContainer>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          復旧作業中のため、
          <br />
          アクセスできなくなっています。
          <br />
          時間をおいて、もう一度試してみてください。
        </BaseTypography>
      </StyledDescriptionContainer>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
