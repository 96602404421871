const types = {
  GET_FREQUENTLY_ASKED_QUESTIONS_START:
    'api/GET_FREQUENTLY_ASKED_QUESTIONS_START',
  GET_FREQUENTLY_ASKED_QUESTIONS_SUCCESS:
    'api/GET_FREQUENTLY_ASKED_QUESTIONS_SUCCESS',
  GET_FREQUENTLY_ASKED_QUESTIONS_FAILURE:
    'api/GET_FREQUENTLY_ASKED_QUESTIONS_FAILURE',
} as const;

export { types };
