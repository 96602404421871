import {
  getFrequentlyAskedQuestionsFailure,
  getFrequentlyAskedQuestionsStart,
  getFrequentlyAskedQuestionsSuccess,
} from './actions';
import { ApiError } from '/@/api/graphql/client';
import type { GetFrequentlyAskedQuestionsResponse } from '/@/api/graphql/publicApi/queries/getFrequentlyAskedQuestions';
import { getFrequentlyAskedQuestions } from '/@/api/graphql/publicApi/queries/getFrequentlyAskedQuestions';
import { ServiceNameEnum } from '/@/api/graphql/publicApi/types';
import type { AppThunk } from '/@/store';

const getFrequentlyAskedQuestionsThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(getFrequentlyAskedQuestionsStart());
    return getFrequentlyAskedQuestions({
      serviceName: ServiceNameEnum['Wallet'],
    })
      .then((response) =>
        dispatch(getFrequentlyAskedQuestionsSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getFrequentlyAskedQuestionsFailureThunk(error)),
      );
  };

const getFrequentlyAskedQuestionsSuccessThunk =
  (response: GetFrequentlyAskedQuestionsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(
      getFrequentlyAskedQuestionsSuccess(
        response.data!.frequentlyAskedQuestions,
      ),
    );
    return Promise.resolve();
  };

const getFrequentlyAskedQuestionsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getFrequentlyAskedQuestionsFailure(error));
    // NOTE: 500 ページに飛ばさないので、errorHandler を利用しない
    return Promise.reject(error);
  };

export { getFrequentlyAskedQuestionsThunk };
