import React from 'react';
import { Presenter } from './Presenter';
import { MyGiftBase } from '/@/store/app/shared/types';

export const MYGIFT_DISPLAY_SIZE = {
  SM: 'sm',
  MD: 'md',
} as const;
export type MyGiftDisplaySize =
  (typeof MYGIFT_DISPLAY_SIZE)[keyof typeof MYGIFT_DISPLAY_SIZE];
type Props = {
  myGift: MyGiftBase;
  myGiftDisplaySize?: MyGiftDisplaySize;
  isHiddenMergeableText?: boolean;
  isHiddenArrowIcon?: boolean;
  isUnclickable?: boolean;
};

const Container: React.VFC<Props> = ({
  myGift,
  myGiftDisplaySize,
  isHiddenMergeableText,
  isHiddenArrowIcon,
  isUnclickable,
}) => {
  return (
    <Presenter
      myGift={myGift}
      myGiftDisplaySize={myGiftDisplaySize}
      isHiddenMergeableText={isHiddenMergeableText}
      isHiddenArrowIcon={isHiddenArrowIcon}
      isUnclickable={isUnclickable}
    />
  );
};
export { Container as MyGift };
