import React from 'react';
import { Presenter, Detail } from './Presenter';

type OwnProps = {
  title: string;
  subTitle: string;
  details: Detail[];
  attention?: string;
};

const Container: React.FC<OwnProps> = ({
  title,
  subTitle,
  details,
  attention,
}) => {
  return (
    <Presenter
      title={title}
      subTitle={subTitle}
      details={details}
      attention={attention}
    />
  );
};

export { Container as DetailSections };
