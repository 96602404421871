import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledAvailableGiftsContainer = styled('div')({});
const StyledGroupedLabelContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
const StyledMyGiftContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledAvailableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4, 2),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
}));
const StyledAvailableMyGiftsNotFoundTextContainer = styled('div')({
  textAlign: 'center',
});
const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getAvailableMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getAvailableMyGiftsConnectionStatus,
}) => (
  <Grid>
    {Object.keys(groupedAvailableMyGifts).length ? (
      <StyledAvailableGiftsContainer data-testid="availableMyGifts">
        {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
          <React.Fragment key={date}>
            <StyledGroupedLabelContainer>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
                {date}
              </BaseTypography>
            </StyledGroupedLabelContainer>
            {myGifts?.map(
              (myGift) =>
                myGift && (
                  <React.Fragment key={myGift.urlCode}>
                    <BaseDivider />
                    <StyledMyGiftContainer>
                      <MyGift myGift={myGift} />
                    </StyledMyGiftContainer>
                  </React.Fragment>
                ),
            )}
          </React.Fragment>
        ))}
      </StyledAvailableGiftsContainer>
    ) : (
      getAvailableMyGiftsConnectionStatus !== STATUS.IDLE &&
      getAvailableMyGiftsConnectionStatus !== STATUS.LOADING && (
        <StyledAvailableMyGiftsNotFoundContainer
          container
          justifyContent="center"
          direction="column"
        >
          <StyledAvailableMyGiftsNotFoundTextContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              現在ご利用可能なギフトはありません。
            </BaseTypography>
          </StyledAvailableMyGiftsNotFoundTextContainer>
          <StyledButtonContainer>
            <BaseButton component={Link} to={PATHS.PROMOTIONS} isTransparent>
              もらえるギフトを確認する
            </BaseButton>
          </StyledButtonContainer>
        </StyledAvailableMyGiftsNotFoundContainer>
      )
    )}
    {getAvailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
