import { GiftTypesEnum } from '/@/api/graphql/internalApi/types';

const expiredAtLabel = (giftType: GiftTypesEnum) =>
  giftType === GiftTypesEnum.GifteeBox
    ? '交換'
    : giftType === GiftTypesEnum.SingleSelect
      ? '選択'
      : '有効';

export default expiredAtLabel;
