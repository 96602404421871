import React from 'react';
import { Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Promotion } from '/@/components/shared/promotion/Index';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { STATUS } from '/@/store/api/constants';
import type { Status } from '/@/store/api/constants';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '../../shared/typography/Index';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  color: theme.palette.secondary.dark,
}));
const StyledLoaderContainer = styled(Grid)({
  position: 'fixed',
  inset: 0,
});

type Props = {
  promotionSpots: PromotionSpotsState;
  getPromotionSpotsStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  promotionSpots,
  getPromotionSpotsStatus,
}) => (
  <MainLayout>
    <StyledContainer maxWidth="sm">
      <Grid container direction="column">
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            もらえるギフト
          </BaseTypography>
        </StyledTitleContainer>
        <StyledDescriptionContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            キャンペーンに参加してギフトをもらおう
          </BaseTypography>
        </StyledDescriptionContainer>
        {getPromotionSpotsStatus === STATUS.LOADING ? (
          <StyledLoaderContainer container alignItems="center">
            <PartialLoader />
          </StyledLoaderContainer>
        ) : (
          <Grid>
            {promotionSpots?.length ? (
              promotionSpots.map((promotionSpot) => (
                <Promotion
                  key={`${promotionSpot.name}${promotionSpot.order}`}
                  promotionSpot={promotionSpot}
                />
              ))
            ) : (
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
                現在応募可能なキャンペーンはありません。
              </BaseTypography>
            )}
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
