import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';

export type PresenterProps = {
  size?: number;
  marginSize?: number;
};

const StyledProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'marginSize',
})<{ size?: number; marginSize?: number }>(
  ({ theme, size = 40, marginSize = 2 }) => ({
    margin: theme.spacing(marginSize),
    width: size,
    height: size,
    color: '#1DABD6',
  }),
);

const Presenter: React.VFC<PresenterProps> = ({ size, marginSize }) => (
  <Grid container justifyContent="center">
    <StyledProgress color="primary" size={size} marginSize={marginSize} />
  </Grid>
);

export { Presenter };
