import { gql } from '@apollo/client';
import { FRAGMENT_POINT_WALLET } from '/@/api/graphql/internalApi/fragments/pointWallet.gql';

export const pointUpdatedSubscriptionGql = gql`
  ${FRAGMENT_POINT_WALLET}
  subscription PointUpdated {
    pointUpdated {
      pointWallet {
        ...PointWalletFields
      }
    }
  }
`;
