import React from 'react';
import { Presenter } from './Presenter';

export type Detail = { key: string; value: string };
export const POINT_FONT_SIZE = {
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const;
export type PointFontSize = 'md' | 'lg' | 'xl';

type Props = {
  points: number | null | undefined;
  fontSize?: PointFontSize;
  isMinus?: boolean;
};

const Container: React.VFC<Props> = ({
  points,
  fontSize = POINT_FONT_SIZE.MD,
  isMinus = false,
}) => {
  return <Presenter points={points} fontSize={fontSize} isMinus={isMinus} />;
};

export { Container as Points };
