import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { Link } from 'react-router-dom';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontWeight: 'bold',
  color: theme.palette.error.main,
  fontSize: '1rem',
}));

const StyledTopPageLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  marginTop: theme.spacing(2),
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledTopPageLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

type Props = {
  destinationPath: string;
  isRendered: boolean;
};

const Presenter: React.VFC<Props> = ({ destinationPath, isRendered }) => (
  <MainLayout>
    {isRendered ? (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <StyledSubtitle variant="body2">無効なURLです</StyledSubtitle>
        <StyledDescription variant="caption">
          自動的にサインイン画面に遷移します
        </StyledDescription>
        <PartialLoader />
        <StyledTopPageLink to={destinationPath}>
          <StyledTopPageLinkText variant="body2">
            サインイン画面に遷移しない場合はこちらをクリック
          </StyledTopPageLinkText>
        </StyledTopPageLink>
      </Grid>
    ) : (
      <PartialLoader />
    )}
  </MainLayout>
);

export { Presenter };
