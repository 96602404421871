import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/system';
import pointChargeImage from '/@/assets/shared/benefitOfPointCharge/illustration/point-charge-image.png';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  width: '100%',
  textAlign: 'center',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledBodyTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
}));
const StyledEnphasizedTextContainer = styled('div')({
  color: '#1DABD6',
});
const StyledAttentionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
}));
const StyledImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledImage = styled('img')({
  display: 'block',
  margin: 'auto',
  width: '80%',
});

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledContainer maxWidth="sm">
    <StyledTitleContainer>
      <BaseTypography
        fontType={FONT_TYPE.HEADLINE}
        fontSize={FONT_SIZE.LG}
        isBold
      >
        ポイントに移行すると
        <br />
        さらにお得
      </BaseTypography>
    </StyledTitleContainer>
    <StyledBodyTextContainer>
      <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
        ポイントへ移行することで
        <br />
        多くの商品と交換ができるのはもちろんのこと、
      </BaseTypography>
      <StyledEnphasizedTextContainer>
        <BaseTypography
          fontType={FONT_TYPE.BODY}
          fontSize={FONT_SIZE.LG}
          isBold
        >
          還元やポイント追加付与などさらに
          <br />
          お得にご利用することができます！
        </BaseTypography>
      </StyledEnphasizedTextContainer>
    </StyledBodyTextContainer>
    <StyledImageContainer>
      <StyledImage src={pointChargeImage} alt="ポイント移行イメージ" />
    </StyledImageContainer>
    <StyledAttentionContainer>
      <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
        ※ 獲得したポイント毎に有効期限が異なります。
        <br />※ 有効期限の近いポイントから利用されます。
      </BaseTypography>
    </StyledAttentionContainer>
  </StyledContainer>
);

export { Presenter };
