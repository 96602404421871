import React from 'react';
import { Grid, styled } from '@mui/material';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import { GroupedAvailablePointCharges } from '/@/store/page/pointLogsPage/availablePointChargesPage/selectors';
import { formatToEndDateJa } from '/@/utils/formatDate';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledPointLogContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
const StyledDividerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0),
}));

type Props = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  groupedAvailablePointChargesByMonth: GroupedAvailablePointCharges;
  getAvailablePointChargesStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  infiniteScrollTarget,
  groupedAvailablePointChargesByMonth,
  getAvailablePointChargesStatus,
}) => (
  <Grid>
    {getAvailablePointChargesStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <StyledPointLogContainer>
        {Object.entries(groupedAvailablePointChargesByMonth).map(
          ([month, availablePointCharges], monthIndex) => (
            <React.Fragment key={month}>
              <Grid>
                {availablePointCharges.map((availablePointCharge, index) => {
                  const title = `${formatToEndDateJa(
                    availablePointCharge.withdrawableUntilAt,
                  )}まで有効`;
                  return (
                    <React.Fragment key={index}>
                      <PointLog
                        title={title}
                        point={availablePointCharge.remainingAmount}
                      />
                      {(monthIndex !==
                        Object.entries(groupedAvailablePointChargesByMonth)
                          .length -
                          1 ||
                        index !== availablePointCharges.length - 1) && (
                        <StyledDividerContainer>
                          <BaseDivider />
                        </StyledDividerContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </React.Fragment>
          ),
        )}
      </StyledPointLogContainer>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
