import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { STATUS, Status } from '/@/store/api/constants';
import { BrandCard } from '/@/components/shared/brandContents/brandCard/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledSectionContents = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(2),
}));
const StyledNoBrandMessageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 0),
  textAlign: 'center',
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
}));

type Props = {
  brands: GetBrandsConnectionQuery['brands']['nodes'];
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getBrandsConnectionStatus: Status;
};
const Presenter: React.VFC<Props> = ({
  brands,
  infiniteScrollTarget,
  getBrandsConnectionStatus,
}) => {
  return (
    <>
      <StyledSectionContents>
        {brands && brands.length > 0 ? (
          <StyledGrid
            container
            justifyContent="flex-start"
            alignItems="center"
            rowGap="32px !important"
          >
            {brands.map(
              (brand, index) =>
                brand && (
                  <BrandCard
                    key={index}
                    index={index}
                    brand={brand}
                    isRecommended={false}
                    isLastItem={index === brands.length - 1}
                  />
                ),
            )}
            {getBrandsConnectionStatus === STATUS.LOADING && <PartialLoader />}
          </StyledGrid>
        ) : getBrandsConnectionStatus === STATUS.LOADING ||
          getBrandsConnectionStatus === STATUS.IDLE ? (
          <PartialLoader />
        ) : (
          getBrandsConnectionStatus === STATUS.SUCCESS && (
            <StyledNoBrandMessageContainer>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
                {' '}
                現在おトクなチケットはありません
              </BaseTypography>
            </StyledNoBrandMessageContainer>
          )
        )}
      </StyledSectionContents>
      <div ref={infiniteScrollTarget} />
    </>
  );
};

export { Presenter };
