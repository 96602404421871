import React from 'react';
import { styled } from '@mui/system';
import { SignInPageState } from '/@/store/page/signInPage';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { Box, Divider } from '@mui/material';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledSavingGiftContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(2),
}));
const StyledSavingGiftCard = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: '64px',
  height: '64px',
  minWidth: '64px',
  marginTop: 0,
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
  overflow: 'hidden',
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  width: 'auto',
}));
const StyledItemNameContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

type Props = {
  getSavingGifteeContentStatus: Status;
  savingGifteeContent: SignInPageState['savingGifteeContent'];
  convertToPoint?: boolean | null;
  destination?: DestinationEnum | null;
  serialCode?: string | null;
};

const Presenter: React.VFC<Props> = ({
  getSavingGifteeContentStatus,
  savingGifteeContent,
  convertToPoint = null,
  destination = null,
  serialCode = null,
}) => (
  <div>
    <Box mt={3} />
    {getSavingGifteeContentStatus === STATUS.LOADING ||
    !savingGifteeContent?.myGifts ? (
      <PartialLoader />
    ) : (
      <StyledSavingGiftContainer>
        <StyledSavingGiftCard>
          {savingGifteeContent.myGifts.map((myGift, index) => (
            <div key={index}>
              {index > 0 && <StyledDivider />}
              <StyledLogoAndTitle>
                <StyledLogoContainer>
                  <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
                </StyledLogoContainer>
                <StyledRightContent>
                  <StyledItemNameContainer>
                    <BaseTypography
                      fontType={FONT_TYPE.BODY}
                      fontSize={FONT_SIZE.MD}
                      isBold
                    >
                      {myGift.contentName}
                    </BaseTypography>
                  </StyledItemNameContainer>
                </StyledRightContent>
              </StyledLogoAndTitle>
            </div>
          ))}
          <Box mt={2} />
          <BaseDivider />
          <Box mt={2} />
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={serialCode}
          >
            ログインしてギフトを保存する
          </LoginButton>
        </StyledSavingGiftCard>
      </StyledSavingGiftContainer>
    )}
  </div>
);

export { Presenter };
