import React from 'react';
import {
  Box,
  List,
  ListItemText,
  Divider,
  Typography,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '/@/assets/shared/icon/neo-check-icon.svg';
import { STATUS, Status } from '/@/store/api/constants';
import { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';

const StyledMenuListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  height: '100%',
  position: 'relative',
  maxHeight: '56px', // safariでの表示崩れ対応
}));
const StyledMenuListItemText = styled(ListItemText)({
  fontSize: '0.875rem',
  lineHeight: '1.75',
});
const StyledCheckIcon = styled('div')({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  height: '20px',
});
const StyledNoExchangeableBrandText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.4',
  textAlign: 'center',
  margin: theme.spacing(2),
}));

type Props = {
  exchangeableBrands: GetBrandsConnectionQuery['brands']['nodes'];
  selectedUid: string | null;
  setBrandCondition: (uid: string) => void;
  getExchangeableBrandsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  exchangeableBrands,
  selectedUid,
  setBrandCondition,
  getExchangeableBrandsConnectionStatus,
}) => (
  <Box>
    <List
      component="nav"
      aria-label="menu-list"
      style={{ padding: '16px', paddingTop: '0px' }}
    >
      {exchangeableBrands?.map(
        (brand, Index) =>
          brand && (
            <div key={Index}>
              <StyledMenuListItem
                onClick={() => setBrandCondition(brand.uid)}
                data-gtm-click="templates-header-brandCondition-setBrandConditionButton"
              >
                <StyledMenuListItemText primary={brand.name} />
                {brand.uid === selectedUid && (
                  <StyledCheckIcon>
                    <img src={CheckIcon} alt="チェック" />
                  </StyledCheckIcon>
                )}
              </StyledMenuListItem>
              <Divider component="li" />
            </div>
          ),
      )}
      {exchangeableBrands?.length === 0 &&
        getExchangeableBrandsConnectionStatus !== STATUS.LOADING && (
          <StyledNoExchangeableBrandText>
            選択可能なブランドがありません
          </StyledNoExchangeableBrandText>
        )}
    </List>
  </Box>
);

export { Presenter };
