import React, { FormEvent, ChangeEvent } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Box,
  Container,
  SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { LoginModal } from './loginModal/Index';
import type {
  InquiriesNewPageInputState,
  TopicValues,
} from '/@/store/page/inquiriesPage/newPage';
import { TOPICS } from '/@/store/page/inquiriesPage/newPage';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { theme } from '/@/utils/theme';
import checkboxCheckedIcon from '/@/assets/shared/icon/checkbox-checked-icon.svg';
import checkboxNotcheckedIcon from '/@/assets/shared/icon/checkbox-notchecked-icon.svg';
import selectboxArrowIcon from '/@/assets/shared/icon/selectbox-arrow-icon.svg';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

type Props = {
  name: InquiriesNewPageInputState['name'];
  email: InquiriesNewPageInputState['email'];
  topic: InquiriesNewPageInputState['topic'];
  content: InquiriesNewPageInputState['content'];
  isTermsAgreed: boolean;
  isReadyToSubmit: boolean;
  isLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTopic: (event: SelectChangeEvent<TopicValues>) => void;
  handleChangeContent: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeIsTermsAgreed: (event: ChangeEvent<HTMLInputElement>) => void;
};

const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));
const StyledCautionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
  borderRadius: theme.spacing(),
}));
const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-asterisk': {
    display: 'none',
  },
});
const StyledFormLabelContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(),
}));
const StyledFormLabel = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledFormLabelChip = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginLeft: theme.spacing(),
}));
const StyledInquiryContentDescriptionContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginBottom: theme.spacing(),
}));
const StyledPrivacyPolicyDescriptionContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `2px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.spacing(1),
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.palette.secondary.dark}`,
      borderColor: `${theme.palette.secondary.dark}`,
    },
  },
}));
const StyledSelectboxArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: '50%',
  transform: 'translateY(-50%)',
}));
const StyledCheckBoxLabel = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

const Presenter: React.FC<Props> = ({
  name,
  email,
  topic,
  content,
  isTermsAgreed,
  isReadyToSubmit,
  isLoading,
  handleSubmit,
  handleChangeName,
  handleChangeEmail,
  handleChangeTopic,
  handleChangeContent,
  handleChangeIsTermsAgreed,
}) => (
  <MainLayout>
    <StyledMainContainer maxWidth="sm">
      <StyledTitleContainer>
        <BaseTypography
          fontType={FONT_TYPE.HEADLINE}
          fontSize={FONT_SIZE.LG}
          isBold
        >
          お問い合わせ
        </BaseTypography>
      </StyledTitleContainer>
      <form onSubmit={handleSubmit} data-testid="inquiry-form">
        <Grid container direction="column" alignItems="stretch" spacing={2}>
          <StyledGridItem>
            <FormControl fullWidth>
              <StyledFormLabelContainer
                container
                direction="row"
                alignItems="center"
              >
                <StyledFormLabel>お名前</StyledFormLabel>
                <StyledFormLabelChip>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                  >
                    *必須
                  </BaseTypography>
                </StyledFormLabelChip>
              </StyledFormLabelContainer>
              <StyledTextField
                id="inquiry-name"
                required
                variant="outlined"
                value={name}
                autoComplete="name"
                onChange={handleChangeName}
                inputProps={
                  {
                    'data-testid': 'inquiry-name-input',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            </FormControl>
          </StyledGridItem>
          <StyledGridItem>
            <FormControl fullWidth>
              <StyledFormLabelContainer
                container
                direction="row"
                alignItems="center"
              >
                <StyledFormLabel>メールアドレス</StyledFormLabel>
                <StyledFormLabelChip>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                  >
                    *必須
                  </BaseTypography>
                </StyledFormLabelChip>
              </StyledFormLabelContainer>
              <StyledTextField
                id="inquiry-email-address"
                required
                variant="outlined"
                value={email}
                autoComplete="email"
                onChange={handleChangeEmail}
                inputProps={
                  {
                    'data-testid': 'inquiry-email-input',
                    type: 'email',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            </FormControl>
          </StyledGridItem>
          <StyledGridItem>
            <FormControl fullWidth>
              <StyledFormLabelContainer
                container
                direction="row"
                alignItems="center"
              >
                <StyledFormLabel id="inquiry-topic-label">
                  お問い合わせの種類
                </StyledFormLabel>
                <StyledFormLabelChip>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                  >
                    *必須
                  </BaseTypography>
                </StyledFormLabelChip>
              </StyledFormLabelContainer>
              <Select
                autoWidth
                labelId="inquiry-topic-label"
                id="inquiry-topic"
                variant="outlined"
                value={topic}
                onChange={handleChangeTopic}
                autoComplete="選択してください"
                inputProps={
                  {
                    'data-testid': 'inquiry-topic-select',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                IconComponent={() => (
                  <StyledSelectboxArrow>
                    <img src={selectboxArrowIcon} alt="selectboxArrowIcon" />
                  </StyledSelectboxArrow>
                )}
                sx={{
                  '& fieldset': {
                    border: `2px solid ${theme.palette.secondary.light}`,
                    borderRadius: theme.spacing(1),
                  },
                  '&:hover fieldset': {
                    border: `2px solid ${theme.palette.secondary.dark}`,
                    borderColor: `${theme.palette.secondary.dark} !important`,
                  },
                  '&.Mui-focused fieldset': {
                    border: `2px solid ${theme.palette.secondary.dark}`,
                    borderColor: `${theme.palette.secondary.dark} !important`,
                  },
                }}
              >
                {Object.entries(TOPICS).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
          <StyledGridItem>
            <FormControl fullWidth>
              <StyledFormLabelContainer
                container
                direction="row"
                alignItems="center"
              >
                <StyledFormLabel>お問い合わせ内容</StyledFormLabel>
                <StyledFormLabelChip>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                  >
                    *必須
                  </BaseTypography>
                </StyledFormLabelChip>
                <StyledInquiryContentDescriptionContainer>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                  >
                    できるだけ具体的に記入してください。より早く、より適切なお返事を送ることができます。
                  </BaseTypography>
                </StyledInquiryContentDescriptionContainer>
              </StyledFormLabelContainer>
              <StyledTextField
                id="inquiry-content"
                fullWidth
                multiline
                minRows={5}
                required
                variant="outlined"
                value={content}
                placeholder="お問い合わせ内容を記載してください"
                onChange={handleChangeContent}
                inputProps={
                  {
                    'data-testid': 'inquiry-content-input',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            </FormControl>
          </StyledGridItem>
          <StyledGridItem>
            <StyledCautionBox>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
                ※迷惑メールの対策、キャリアメール等、ドメイン指定を行っている場合、メールが受信できない場合がございます。「@giftee.co」を受信設定してください。
              </BaseTypography>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
                ※メールの対応は平日のみとなっております。週末や祝日にいただいたお問い合わせは、翌営業日以降（平日）に確認させていただきますのでご了承ください。
              </BaseTypography>
            </StyledCautionBox>
          </StyledGridItem>
          <StyledPrivacyPolicyDescriptionContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              <a
                href="https://giftee.com/privacy_policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                お問い合わせに関する個人情報のお取り扱いについて
              </a>
              <br />
              同意の上のでお問い合わせを送信してください
            </BaseTypography>
          </StyledPrivacyPolicyDescriptionContainer>
          <StyledGridItem>
            <StyledFormControlLabel
              label={
                <StyledCheckBoxLabel>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.MD}
                  >
                    同意する
                  </BaseTypography>
                </StyledCheckBoxLabel>
              }
              control={
                <Checkbox
                  name="agreeWithTheTreatmentOfPersonalData"
                  required
                  checked={isTermsAgreed}
                  onChange={handleChangeIsTermsAgreed}
                  inputProps={
                    {
                      'data-testid': 'inquiry-terms-checkbox',
                    } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                  icon={
                    <img
                      src={checkboxNotcheckedIcon}
                      alt="checkboxNotcheckedIcon"
                    />
                  }
                  checkedIcon={
                    <img src={checkboxCheckedIcon} alt="checkboxCheckedIcon" />
                  }
                />
              }
            />
          </StyledGridItem>
          <StyledGridItem>
            <BaseButton
              fullWidth
              type="submit"
              disabled={!isReadyToSubmit}
              loading={isLoading}
            >
              送信する
            </BaseButton>
          </StyledGridItem>
        </Grid>
      </form>
    </StyledMainContainer>
    <LoginModal />
  </MainLayout>
);

export { Presenter };
