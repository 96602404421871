import React from 'react';
import { Modal, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { BenefitOfPointCharge } from '../illustration/Index';
import { BaseButton } from '../../button/baseButton/Index';

const StyledModal = styled(Modal)({
  backgroundColor: 'transparent',
  textAlign: 'center',
});
const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  width: 'calc(100% - 32px)',
  maxWidth: '480px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  alignItems: 'center',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledHandleCloseModalButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  handleClose: () => void;
};
const Presenter: React.VFC<Props> = ({ handleClose }) => (
  <StyledModal open={true} onClose={handleClose}>
    <StyledBox>
      <Grid container direction="column" justifyContent="center">
        <BenefitOfPointCharge />
        <StyledHandleCloseModalButtonContainer>
          <BaseButton
            onClick={handleClose}
            isTransparent={true}
            data-gtm-click="shared-benefitOfPointCharge-modal-closeButton"
          >
            閉じる
          </BaseButton>
        </StyledHandleCloseModalButtonContainer>
      </Grid>
    </StyledBox>
  </StyledModal>
);

export { Presenter };
