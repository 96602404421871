import { Reducer } from 'redux';
import { saveGifteeContentTypes } from '/@/store/api/mutations/saveGifteeContent';
import { getGifteeContentTypes } from '/@/store/api/queries/getGifteeContent';
import type { RootAction } from '/@/store/actions';
import { GifteeContentBase } from '/@/store/app/shared/types';

type SelectedGifteeContentState = SelectedGifteeContent | null;

type SelectedGifteeContent = GifteeContentBase;

const initialState: SelectedGifteeContentState = null;

const selectedGifteeContent: Reducer<SelectedGifteeContentState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case saveGifteeContentTypes.SAVE_GIFTEE_CONTENT_SUCCESS:
    case getGifteeContentTypes.GET_GIFTEE_CONTENT_SUCCESS: {
      return action.gifteeContent;
    }
    default:
      return state;
  }
};

export { selectedGifteeContent };
export type { SelectedGifteeContentState };
