import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import MergeToPointImage from '/@/assets/page/lpPage/lpMergeToPointPage/neo-lp-hero-top-image.png';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';
import { PATHS } from '/@/routes/paths';
import { LoginButton } from '/@/components/shared/loginButton/Index';

const StyledContainer = styled('div')({
  width: '100%',
});
const StyledSubTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.common.black,
}));
const StyledImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(1),
}));
const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '100%',
  padding: theme.spacing(2),
  paddingBottom: 0,
}));
const StyledLoginButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <StyledContainer>
    <Grid container direction="column" alignContent="center">
      <Grid size="grow" textAlign="center">
        <StyledSubTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            えらべるPayは
          </BaseTypography>
        </StyledSubTitleContainer>
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            gift wallet ポイントへ
            <br />
            まとめると便利！
          </BaseTypography>
        </StyledTitleContainer>
        <StyledDescriptionContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            ポイントの還元やポイントのまとめて利用など、
            <br />
            今よりもさらにおトクにポイントが活用できます。
          </BaseTypography>
        </StyledDescriptionContainer>
        <StyledImageContainer>
          <StyledImage src={MergeToPointImage} alt="mergeToPointImage" />
        </StyledImageContainer>
      </Grid>
      <Grid size="grow">
        <StyledLoginButtonContainer>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={null}
            flow={PATHS.LP_MERGE_TO_POINT}
            isDisplayNoteForPointConvert
          >
            ギフトをポイントに移行する
          </LoginButton>
        </StyledLoginButtonContainer>
      </Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
