import React from 'react';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';

const Presenter: React.VFC<Record<string, never>> = () => (
  <>
    <MainLayout>
      <div data-testid="page-giftNew">
        <PageLoader />
      </div>
    </MainLayout>
  </>
);

export { Presenter };
