import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  children: React.ReactNode;
  alwaysSticky?: boolean; // MEMO: 常に表示する場合はtrueを指定
};

const Container: React.FC<OwnProps> = ({ children, alwaysSticky }) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    if (alwaysSticky) {
      return;
    } else {
      const handleScroll = () => {
        setIsSticky(window.scrollY > 200);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [alwaysSticky]);

  return <Presenter isSticky={alwaysSticky ?? isSticky}>{children}</Presenter>;
};

export { Container as Sticky };
