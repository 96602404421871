import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';

export type Detail = {
  number: number;
  title: string;
  description: string;
  subDescription?: string;
  imgTitle?: string;
  img?: string;
};

type Props = {
  title: string;
  subTitle: string;
  details: Detail[];
  attention?: string;
};

const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  backgroundColor: theme.palette.background.contrast,
  borderRadius: theme.spacing(2),
}));
const StyledSectionTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledDetailTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));
const StyledDetailNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  height: '26px',
  width: '26px',
  borderRadius: '2px 8px 8px 2px',
  margin: '0 auto',
  backgroundColor: '#1DABD6',
  textAlign: 'center',
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold',
}));
const StyledDetailTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));
const StyledDetailDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
}));
const StyledSubDetailDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.grey[700],
}));
const StyledDetailImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
const StyledDetailImageTitleContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(0.625),
  color: theme.palette.grey[700],
}));
const StyledDetailImage = styled('img')({
  width: '100%',
});
const StyledAttentionContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

const Presenter: React.VFC<Props> = ({
  title,
  subTitle,
  details,
  attention,
}) => (
  <StyledSection container direction="row" justifyContent="center">
    <Grid textAlign="center">
      <StyledSectionTitleContainer>
        <BaseTypography
          fontType={FONT_TYPE.HEADLINE}
          fontSize={FONT_SIZE.LG}
          isBold
          component="h2"
        >
          {title}
        </BaseTypography>
        <BaseTypography
          fontType={FONT_TYPE.BODY}
          fontSize={FONT_SIZE.MD}
          isBold
          component="div"
        >
          {subTitle}
        </BaseTypography>
      </StyledSectionTitleContainer>
    </Grid>

    {details.map((detail) => (
      <Grid key={detail.number}>
        <StyledDetailTextContainer>
          <StyledDetailNumber>{detail.number}</StyledDetailNumber>
          <StyledDetailTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.HEADLINE}
              fontSize={FONT_SIZE.MD}
              isBold
              component="h3"
            >
              {detail.title}
            </BaseTypography>
          </StyledDetailTitleContainer>
          <StyledDetailDescriptionContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.MD}
              component="div"
            >
              {detail.description}
            </BaseTypography>
          </StyledDetailDescriptionContainer>
          {detail.subDescription && (
            <StyledSubDetailDescriptionContainer>
              <BaseTypography
                fontType={FONT_TYPE.BODY}
                fontSize={FONT_SIZE.SM}
                component="div"
              >
                {detail.subDescription}
              </BaseTypography>
            </StyledSubDetailDescriptionContainer>
          )}
          {detail.img && detail.img !== '' && (
            <StyledDetailImageContainer>
              {detail.imgTitle && (
                <StyledDetailImageTitleContainer>
                  <BaseTypography
                    fontType={FONT_TYPE.BODY}
                    fontSize={FONT_SIZE.SM}
                    component="span"
                  >
                    {detail.imgTitle}
                  </BaseTypography>
                </StyledDetailImageTitleContainer>
              )}
              {detail.img && (
                <StyledDetailImage src={detail.img} alt={detail.title} />
              )}
            </StyledDetailImageContainer>
          )}
        </StyledDetailTextContainer>
      </Grid>
    ))}

    {attention && (
      <Grid size={{ xs: 12 }}>
        <StyledAttentionContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.SM}
            component="span"
          >
            {attention}
          </BaseTypography>
        </StyledAttentionContainer>
      </Grid>
    )}
  </StyledSection>
);

export { Presenter };
