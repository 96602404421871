import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import walletLogoInCircle from '/@/assets/shared/icon/wallet-logo-in-circle.svg';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';
import { Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { PointWalletFieldsFragment } from '/@/api/graphql/internalApi/types';

type PointWallet = PointWalletFieldsFragment;
const StyledPointChargedContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(2),
  width: 'auto',
}));
const StyledPointChargeIcon = styled('img')({
  margin: 'auto',
});
const StyledPointChargedTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(1),
}));
const StyledPointDisplayArea = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
}));
const StyledPointBalanceText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.75',
  color: theme.palette.secondary.dark,
  marginBottom: theme.spacing(2),
}));
const StyledNavigateToHomeButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

type Props = {
  pointWallet: PointWallet | null;
  navigateToHome: () => void;
  getPointWalletStatus: Status;
};
const Presenter: React.VFC<Props> = ({
  pointWallet,
  navigateToHome,
  getPointWalletStatus,
}) => (
  <MainLayout>
    <StyledPointChargedContainer maxWidth="sm">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledPointChargeIcon
          src={walletLogoInCircle}
          alt="ギフトを移行しました"
          width="60"
          height="60"
        />
        <StyledPointChargedTitle>ギフトを移行しました</StyledPointChargedTitle>
        <StyledPointDisplayArea>
          <StyledPointBalanceText>ポイント残高</StyledPointBalanceText>
          {getPointWalletStatus === 'loading' ||
          pointWallet?.requirePointRefresh ? (
            <PartialLoader />
          ) : (
            <Points points={pointWallet?.point} fontSize={POINT_FONT_SIZE.XL} />
          )}
        </StyledPointDisplayArea>
        <StyledNavigateToHomeButtonContainer>
          <BaseButton
            disableElevation
            onClick={navigateToHome}
            data-gtm-click="pointPage-chargePage-completionPage-navigateToHomeButton"
          >
            マイギフトへ戻る
          </BaseButton>
        </StyledNavigateToHomeButtonContainer>
      </Grid>
    </StyledPointChargedContainer>
    <ItemDetailModal />
  </MainLayout>
);

export { Presenter };
