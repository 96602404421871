import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import type { Detail } from './Index';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';

const StyledCard = styled('div')({});
const StyledCardContent = styled(Grid)({
  minWidth: 0,
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.secondary.dark,
  fontSize: '0.875rem',
  lineHeight: '1.75',
}));
const StyledDetailContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[700],
}));
const StyledPoint = styled(Typography)({
  fontSize: '1.125rem',
  lineHeight: '1',
  fontWeight: 'bold',
  fontFamily: 'Grandstander-Bold',
  transform: 'translateY(2px)', // MEMO: 天地中央に合わせるための調整
});

type Props = {
  title: string;
  details?: Detail[];
  point: number;
};

const Presenter: React.VFC<Props> = ({ title, details, point }) => (
  <StyledCard>
    <Grid container wrap="nowrap" direction="row">
      <StyledCardContent
        size={{ xs: 9 }}
        container
        justifyContent="center"
        direction="column"
      >
        <StyledTitle variant="subtitle1">{title}</StyledTitle>
        {details && (
          <>
            {details.map((detail, index) => (
              <StyledDetailContainer key={index}>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.SM}
                >
                  {detail.key} : {detail.value}
                </BaseTypography>
              </StyledDetailContainer>
            ))}
          </>
        )}
      </StyledCardContent>
      <Grid
        size={{ xs: 3 }}
        container
        justifyContent="flex-end"
        alignItems="center"
        direction="row"
      >
        <StyledPoint>{point.toLocaleString('ja-JP')}</StyledPoint>
      </Grid>
    </Grid>
  </StyledCard>
);

export { Presenter };
