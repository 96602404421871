import { Reducer } from 'redux';
import { GetFrequentlyAskedQuestionsGqlQuery } from '/@/api/graphql/publicApi/types';
import type { RootAction } from '/@/store/actions';
import { getFrequentlyAskedQuestionsTypes } from '/@/store/api/queries/getFrequentlyAskedQuestions';

type FrequentlyAskedQuestionsState = FrequentlyAskedQuestions | null;
type FrequentlyAskedQuestions =
  GetFrequentlyAskedQuestionsGqlQuery['frequentlyAskedQuestions'];

const frequentlyAskedQuestions: Reducer<
  FrequentlyAskedQuestionsState,
  RootAction
> = (state = null, action) => {
  switch (action.type) {
    case getFrequentlyAskedQuestionsTypes.GET_FREQUENTLY_ASKED_QUESTIONS_SUCCESS:
      return action.frequentlyAskedQuesgtions;
    default:
      return state;
  }
};

export { frequentlyAskedQuestions };
export type { FrequentlyAskedQuestions, FrequentlyAskedQuestionsState };
