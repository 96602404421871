import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  backgroundColor: theme.palette.background.contrast,
  borderRadius: theme.spacing(2),
}));
const StyledTitleContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.dark,
}));
const StyledListTitleContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
  '& + &': {
    marginTop: theme.spacing(3),
  },
}));
const StyledHighReturnBrandsImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(1.5),
}));
const StyledExchangeableBrandsImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(1.5),
}));

type Props = {
  children?: React.ReactNode;
  highReturnBrandsImageURL: string;
  exchangeableBrandsImageURL: string;
};
const Presenter: React.VFC<Props> = ({
  children,
  highReturnBrandsImageURL,
  exchangeableBrandsImageURL,
}) => (
  <StyledContainer>
    <Grid container direction="column" alignContent="center">
      <Grid size="grow" textAlign="center">
        <StyledTitleContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            いまなら
          </BaseTypography>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.LG}
            isBold
          >
            最大10%のポイント還元
          </BaseTypography>
        </StyledTitleContainer>
      </Grid>
      <Grid size="grow">
        <StyledDescriptionContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            gift wallet
            でギフトを交換すると、その場で還元されるおトクなギフトも多数。
            <br />
            今なら最大10%還元となるギフトも！
          </BaseTypography>
        </StyledDescriptionContainer>
      </Grid>
      {highReturnBrandsImageURL !== '' && (
        <Grid size="grow">
          <StyledListTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              高還元ブランド例
            </BaseTypography>
          </StyledListTitleContainer>
          <StyledHighReturnBrandsImage
            src={highReturnBrandsImageURL}
            alt="highReturnBrandsImage"
          />
        </Grid>
      )}
      {exchangeableBrandsImageURL !== '' && (
        <Grid size="grow">
          <StyledListTitleContainer>
            <BaseTypography
              fontType={FONT_TYPE.BODY}
              fontSize={FONT_SIZE.MD}
              isBold
            >
              その他交換可能なギフトも多数！
            </BaseTypography>
          </StyledListTitleContainer>
          <StyledExchangeableBrandsImage
            src={exchangeableBrandsImageURL}
            alt="exchangeableBrandsImage"
          />
        </Grid>
      )}
      {children && <Grid size="grow">{children}</Grid>}
    </Grid>
  </StyledContainer>
);

export { Presenter };
