import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { AnnouncementsList } from '/@/components/shared/announcementsList/Index';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

const StyledAnnouncementsContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0) + ' !important',
  textAlign: 'center',
}));
const StyledTopContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}));
const StyledTopTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));
const StyledReadAnnouncementsButton = styled(Button)(({ theme }) => ({
  minWidth: '0',
  padding: 0,
  color: theme.palette.primary.dark,
  '&.MuiButton-contained': {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.hint,
      color: theme.palette.secondary.main,
    },
  },
}));
const StyledReadAnnouncementsButtonLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '0.75rem',
  lineHeight: '1.5',
  textDecoration: 'underline',
}));
const StyledNoAnnouncementTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: '1rem',
  lineHeight: '1.6',
  fontWeight: 'bold',
}));
const StyledNoAnnouncementText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  fontSize: '0.875rem',
  lineHeight: '1.4',
  //改行を反映させるため
  whiteSpace: 'pre-line',
}));
const StyledModalContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));
const StyledModalCloseButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  headerTitle: string;
  handleReadAllAnnouncements: () => void;
  readAnnouncementsStatus: Status;
  isDisabledReadButton: boolean;
  isNoAnnouncement: boolean;
  noAnnouncementText: string;
  userAnnouncementTypeCategory: UserAnnouncementTypeCategoryUidEnumType;
  isDisplayModal: boolean;
  setIsDisplayModal: (isDisplayModal: boolean) => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  handleReadAllAnnouncements,
  readAnnouncementsStatus,
  isDisabledReadButton,
  isNoAnnouncement,
  noAnnouncementText,
  userAnnouncementTypeCategory,
  isDisplayModal,
  setIsDisplayModal,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledAnnouncementsContainer
      data-testid={`page-announcements-${userAnnouncementTypeCategory}`}
    >
      {isNoAnnouncement ? (
        <>
          <StyledNoAnnouncementTitle>
            現在お知らせはありません
          </StyledNoAnnouncementTitle>
          <StyledNoAnnouncementText>
            {noAnnouncementText}
          </StyledNoAnnouncementText>
        </>
      ) : (
        <StyledTopContainer>
          <StyledTopTextContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM}>
              直近180日以内のお知らせを表示しています
            </BaseTypography>
          </StyledTopTextContainer>
          {readAnnouncementsStatus === STATUS.LOADING ? (
            <PartialLoader />
          ) : (
            <StyledReadAnnouncementsButton
              variant="text"
              disableElevation
              onClick={() => {
                if (isDisabledReadButton) {
                  setIsDisplayModal(true);
                } else {
                  handleReadAllAnnouncements();
                }
              }}
            >
              <StyledReadAnnouncementsButtonLabel
                color="inherit"
                variant="caption"
              >
                全て既読にする
              </StyledReadAnnouncementsButtonLabel>
            </StyledReadAnnouncementsButton>
          )}
        </StyledTopContainer>
      )}
      <AnnouncementsList
        userAnnouncementTypeCategory={[userAnnouncementTypeCategory]}
      />
    </StyledAnnouncementsContainer>
    {isDisplayModal && (
      <Modal handleClose={() => setIsDisplayModal(false)}>
        <StyledModalContent>
          <BaseTypography
            fontType={FONT_TYPE.HEADLINE}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            未読のお知らせはありません
          </BaseTypography>
          <StyledModalCloseButtonContainer>
            <BaseButton onClick={() => setIsDisplayModal(false)} isTransparent>
              閉じる
            </BaseButton>
          </StyledModalCloseButtonContainer>
        </StyledModalContent>
      </Modal>
    )}
  </MainLayout>
);

export { Presenter };
