export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

export type AvailablePointCharge = {
  /** ポイント数 */
  pointAmount: Scalars['Int']['output'];
  /** 残りポイント数 */
  remainingAmount: Scalars['Int']['output'];
  /** ユニークID */
  uid: Scalars['String']['output'];
  /** 引き出し可能期限 */
  withdrawableUntilAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for AvailablePointCharge. */
export type AvailablePointChargeConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AvailablePointChargeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AvailablePointCharge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AvailablePointChargeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AvailablePointCharge>;
};

export type Brand = {
  /** 現在のポイントバックキャンペーン */
  currentPointBackCampaign: Maybe<PointBackCampaign>;
  /** ブランド詳細 */
  description: Scalars['String']['output'];
  /** アイコンURL */
  iconUrl: Scalars['String']['output'];
  /** 最大ポイント */
  maxPoint: Maybe<Scalars['Int']['output']>;
  /** 最小ポイント */
  minPoint: Maybe<Scalars['Int']['output']>;
  /** ブランド名 */
  name: Scalars['String']['output'];
  /** ポイントに関する注意事項 */
  pointCaution: Scalars['String']['output'];
  /** ユニークID */
  uid: Scalars['String']['output'];
};

/** The connection type for Brand. */
export type BrandConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<BrandEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Brand>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BrandEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Brand>;
};

export type BrandType_SortConditionInputType = {
  /** ソート方向 */
  direction: SortDirectionEnum;
  /** ソート対象 */
  target: BrandType_SortConditionTargetEnumType;
};

export enum BrandType_SortConditionTargetEnumType {
  /** ブランドのpointでソート */
  Point = 'point',
  /** ブランドのpositionでソート */
  Position = 'position',
}

export type Content = {
  /** 画像ファイルURL */
  imageFileUrl: Scalars['String']['output'];
  /** リンクURL */
  linkUrl: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateGiftConvertToPointRequest */
export type CreateGiftConvertToPointRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  convertPointAmount: Scalars['Int']['input'];
  myGiftUrlCode: Scalars['String']['input'];
  requestUid: Scalars['String']['input'];
};

/** Autogenerated return type of CreateGiftConvertToPointRequest. */
export type CreateGiftConvertToPointRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  giftConvertToPointRequest: Maybe<GiftConvertToPointRequest>;
};

/** Autogenerated input type of CreatePointChargeWithSerialCode */
export type CreatePointChargeWithSerialCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serialCode: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePointChargeWithSerialCode. */
export type CreatePointChargeWithSerialCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  pointCharge: Maybe<PointCharge>;
  pointWallet: Maybe<PointWallet>;
};

/** Autogenerated input type of CreatePointExchange */
export type CreatePointExchangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemUid: Scalars['String']['input'];
  pointAmount: Scalars['Int']['input'];
  pointBackCampaignUid?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  requestUid: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePointExchange. */
export type CreatePointExchangePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  pointExchange: Maybe<PointExchange>;
  pointWallet: Maybe<PointWallet>;
};

/** Autogenerated input type of CreateUserProfile */
export type CreateUserProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['String']['input'];
  genderCode: Scalars['Int']['input'];
  month: Scalars['String']['input'];
  prefectureCode: Scalars['Int']['input'];
  year: Scalars['String']['input'];
};

/** Autogenerated return type of CreateUserProfile. */
export type CreateUserProfilePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DiscardMyGifts */
export type DiscardMyGiftsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  myGiftUrlCodes: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of DiscardMyGifts. */
export type DiscardMyGiftsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  myGifts: Array<MyGift>;
};

export type Error = {
  extensions: ErrorExtensions;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export enum ErrorCodeEnumType {
  CampaignReachedLimitation = 'CAMPAIGN_REACHED_LIMITATION',
  DuplicatedGifteeContent = 'DUPLICATED_GIFTEE_CONTENT',
  ExchangeError = 'EXCHANGE_ERROR',
  ItemNotAvailable = 'ITEM_NOT_AVAILABLE',
  LineAuthenticationFailed = 'LINE_AUTHENTICATION_FAILED',
  PointBackCampaignNotAvailable = 'POINT_BACK_CAMPAIGN_NOT_AVAILABLE',
  PointWalletBalanceShortage = 'POINT_WALLET_BALANCE_SHORTAGE',
  SerialCodeNotFound = 'SERIAL_CODE_NOT_FOUND',
  SerialCodeOutOfPeriod = 'SERIAL_CODE_OUT_OF_PERIOD',
  SerialCodeUsed = 'SERIAL_CODE_USED',
}

export type ErrorExtensions = {
  code: Maybe<ErrorCodeEnumType>;
  status: Scalars['Int']['output'];
};

export type Gender = {
  /** 性別コード */
  code: Scalars['Int']['output'];
  /** 性別名 */
  name: Scalars['String']['output'];
};

/** Autogenerated return type of GiftConvertToPointCompletion. */
export type GiftConvertToPointCompletionPayload = {
  giftConvertToPointRequest: Maybe<GiftConvertToPointRequest>;
};

export type GiftConvertToPointRequest = {
  /** 変換ポイント数 */
  convertPointAmount: Scalars['Int']['output'];
  /** エラーメッセージ */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** マイギフト情報 */
  myGift: MyGift;
  /** ポイントウォレット情報 */
  pointWallet: PointWallet;
  /** リクエストID */
  requestUid: Scalars['String']['output'];
};

export enum GiftTypesEnum {
  GifteeBox = 'giftee_box',
  SimpleGift = 'simple_gift',
  SingleSelect = 'single_select',
}

export type GifteeContent = {
  /** マイギフト一覧 */
  myGifts: Array<MyGift>;
  /** 保存日時 */
  savedAt: Scalars['ISO8601DateTime']['output'];
  /** URLコード */
  urlCode: Scalars['String']['output'];
};

export type Item = {
  /** ブランド情報 */
  brand: Brand;
  /** 注意事項 */
  caution: Scalars['String']['output'];
  /** コンテンツ画像URL */
  contentImageUrl: Scalars['String']['output'];
  /** コンテンツ名 */
  contentName: Scalars['String']['output'];
  /** 現在のアイテムポイントフェーズ */
  currentItemPointPhase: Maybe<ItemPointPhase>;
  /** 現在のポイントバックキャンペーン */
  currentPointBackCampaign: Maybe<PointBackCampaign>;
  /** 説明 */
  description: Scalars['String']['output'];
  /** 読みやすい有効期限 */
  readableExpiration: Scalars['String']['output'];
  /** ユニークID */
  uid: Scalars['String']['output'];
};

/** The connection type for Item. */
export type ItemConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Item>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Item>;
};

export type ItemPointPhase = {
  /** 終了日時 */
  endAt: Scalars['ISO8601DateTime']['output'];
  /** ポイント */
  point: Scalars['Int']['output'];
  /** 開始日時 */
  startAt: Scalars['ISO8601DateTime']['output'];
  /** 変数情報 */
  variable: Maybe<Variable>;
};

export type ItemType_SortConditionInputType = {
  /** ソート方向 */
  direction: SortDirectionEnum;
  /** ソート対象 */
  target: ItemType_SortConditionTargetEnumType;
};

export enum ItemType_SortConditionTargetEnumType {
  /** アイテムのbrand_positionでソート */
  BrandPosition = 'brand_position',
  /** アイテムのmonthly_exchange_countでソート */
  MonthlyExchangeCount = 'monthly_exchange_count',
  /** アイテムのpointでソート */
  Point = 'point',
  /** アイテムのpositionでソート */
  Position = 'position',
}

export enum LayoutEnum {
  /** card_imageレイアウト */
  CardImage = 'card_image',
  /** full_imageレイアウト */
  FullImage = 'full_image',
}

export type Mutation = {
  /** ギフトポイント変換リクエスト作成 */
  createGiftConvertToPointRequest: CreateGiftConvertToPointRequestPayload;
  /** シリアルコードでポイントチャージ作成 */
  createPointChargeWithSerialCode: CreatePointChargeWithSerialCodePayload;
  /** ポイント交換作成 */
  createPointExchange: CreatePointExchangePayload;
  /** ユーザープロファイル作成 */
  createUserProfile: CreateUserProfilePayload;
  /** マイギフト破棄 */
  discardMyGifts: DiscardMyGiftsPayload;
  /** ユーザーお知らせ既読 */
  readUserAnnouncement: ReadUserAnnouncementPayload;
  /** 複数ユーザーお知らせ既読 */
  readUserAnnouncements: ReadUserAnnouncementsPayload;
  /** マイギフト更新 */
  refreshMyGift: RefreshMyGiftPayload;
  /** ギフティーコンテンツ保存 */
  saveGifteeContent: SaveGifteeContentPayload;
};

export type MutationCreateGiftConvertToPointRequestArgs = {
  input: CreateGiftConvertToPointRequestInput;
};

export type MutationCreatePointChargeWithSerialCodeArgs = {
  input: CreatePointChargeWithSerialCodeInput;
};

export type MutationCreatePointExchangeArgs = {
  input: CreatePointExchangeInput;
};

export type MutationCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};

export type MutationDiscardMyGiftsArgs = {
  input: DiscardMyGiftsInput;
};

export type MutationReadUserAnnouncementArgs = {
  input: ReadUserAnnouncementInput;
};

export type MutationReadUserAnnouncementsArgs = {
  input: ReadUserAnnouncementsInput;
};

export type MutationRefreshMyGiftArgs = {
  input: RefreshMyGiftInput;
};

export type MutationSaveGifteeContentArgs = {
  input: SaveGifteeContentInput;
};

export type MyGift = MyGiftInterface & {
  changedAt: Scalars['ISO8601DateTime']['output'];
  contentName: Scalars['String']['output'];
  /** 利用済にした日時 */
  discardedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 有効期限 */
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  giftType: GiftTypesEnum;
  giftUrl: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  /** ポイント移管を行ったか */
  pointConverted: Scalars['Boolean']['output'];
  /** gift walletポイントへの移管可否 */
  pointMergeable: Scalars['Boolean']['output'];
  /** remaining_point はキャッシュを返す。最新のデータを取得したい場合はmutationのrefreshMyGiftから取得すること。キャッシュデータは日次jobで更新しています。 */
  remainingPoint: Scalars['Int']['output'];
  /** 保存日時 */
  savedAt: Scalars['ISO8601DateTime']['output'];
  /** 利用不可になる日時(有効期限 or 利用済にした日時) */
  unavailableAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  urlCode: Scalars['String']['output'];
};

/** The connection type for MyGift. */
export type MyGiftConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MyGiftEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MyGift>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MyGiftEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MyGift>;
};

export type MyGiftInterface = {
  contentName: Scalars['String']['output'];
  /** 有効期限 */
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  giftType: GiftTypesEnum;
  giftUrl: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  /** remaining_point はキャッシュを返す。最新のデータを取得したい場合はmutationのrefreshMyGiftから取得すること。キャッシュデータは日次jobで更新しています。 */
  remainingPoint: Scalars['Int']['output'];
};

export type MyGiftSortConditionInputType = {
  /** ソート方向 */
  direction: SortDirectionEnum;
  /** ソート対象 */
  target: MyGiftSortConditionTargetEnumType;
};

export enum MyGiftSortConditionTargetEnumType {
  /** 変更日時でソート */
  ChangedAt = 'changed_at',
  /** 有効期限でソート */
  ExpiredAt = 'expired_at',
  /** 利用不可日時でソート */
  UnavailableAt = 'unavailable_at',
}

export enum MyGiftUsageStatusEnum {
  /** 利用可能 */
  Available = 'available',
  /** 破棄済み */
  Discarded = 'discarded',
  /** 期限切れ */
  Expired = 'expired',
}

export enum OriginEnum {
  G4b = 'g4b',
  Gcp = 'gcp',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PointBackCampaign = {
  /** 一意キー */
  beginAt: Scalars['ISO8601DateTime']['output'];
  /** 詳細 */
  description: Scalars['String']['output'];
  /** ポイントバック率(%) */
  discountPercentage: Scalars['Int']['output'];
  /** ポイントバック率 */
  discountRate: Scalars['Float']['output'];
  /** 一意キー */
  endAt: Scalars['ISO8601DateTime']['output'];
  /** 限定キャンペーンかどうかのフラグ */
  limited: Scalars['Boolean']['output'];
  /** 通常時ポイントバック率(%) */
  normalDiscountPercentage: Maybe<Scalars['Int']['output']>;
  /** 通常時ポイントバック率 */
  normalDiscountRate: Maybe<Scalars['Float']['output']>;
  /** 一意キー */
  uid: Scalars['String']['output'];
};

export type PointBackCampaignApply = {
  /** ポイントバックされる予定のポイント */
  backPoint: Scalars['Int']['output'];
  /** ポイント還元率 */
  discountRate: Scalars['Float']['output'];
  /** 交換商品単価 */
  pointAmount: Scalars['Int']['output'];
  /** ポイントバックキャンペーン設定 */
  pointBackCampaign: PointBackCampaign;
  /** 消費ポイント */
  purchasePoints: Scalars['Int']['output'];
};

export type PointCharge = {
  /** ポイント数 */
  pointAmount: Scalars['Int']['output'];
  /** 引き出し可能期限 */
  withdrawableUntilAt: Scalars['ISO8601DateTime']['output'];
};

export type PointExchange = {
  /** 商品 */
  item: Item;
  /** 交換されたギフト */
  myGifts: Array<MyGift>;
  /** 交換するポイント(ポイントが設定と違う場合エラーになります) */
  pointAmount: Scalars['Int']['output'];
  /** ポイントバック情報(キャンペーン適用時のみ) */
  pointBackCampaignApply: Maybe<PointBackCampaignApply>;
  /** ログ情報 */
  pointLog: PointLog;
  /** 交換する数 */
  quantity: Scalars['Int']['output'];
  /** 冪等性担保のためのキー。同じrequest_uidの場合同じ結果が返ります。 */
  requestUid: Scalars['String']['output'];
};

export type PointExpired = {
  /** 期限切れポイント */
  expiredPoint: Scalars['Int']['output'];
};

export type PointFeature = {
  /** 遷移先情報 */
  destination: PointFeatureDestination;
  /** 画像URL */
  imageUrl: Scalars['String']['output'];
  /** タイトル / ALT */
  title: Scalars['String']['output'];
  /** ユニークキー */
  uid: Scalars['String']['output'];
};

export type PointFeatureDestination = {
  /** 遷移先種別 */
  kind: PointFeatureDestinationKindEnum;
  /** ユニークキー */
  uid: Scalars['String']['output'];
};

export enum PointFeatureDestinationKindEnum {
  Brand = 'brand',
  Item = 'item',
}

export type PointFeatureGroup = {
  /** 表示タイトル */
  label: Scalars['String']['output'];
  /** 表示レイアウト */
  layout: LayoutEnum;
  /** 表示コンテンツ */
  pointFeatures: Array<PointFeature>;
  /** ユニークキー */
  uid: Scalars['String']['output'];
};

/** The connection type for PointFeatureGroup. */
export type PointFeatureGroupConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PointFeatureGroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PointFeatureGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PointFeatureGroupEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PointFeatureGroup>;
};

export type PointLog = {
  /** 記録日時 */
  loggedAt: Scalars['ISO8601DateTime']['output'];
  /** 明細情報 */
  statement: PointLogStatementUnion;
  /** ステータス */
  status: PointLogType_StatusEnumType;
  /** タイトル */
  title: Scalars['String']['output'];
};

/** The connection type for PointLog. */
export type PointLogConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PointLogEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PointLog>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PointLogEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PointLog>;
};

export type PointLogStatementUnion = PointCharge | PointExchange | PointExpired;

export enum PointLogType_StatusEnumType {
  /** failureステータス */
  Failure = 'failure',
  /** not_requestステータス */
  NotRequest = 'not_request',
  /** retryingステータス */
  Retrying = 'retrying',
  /** successステータス */
  Success = 'success',
}

/** Autogenerated return type of PointUpdated. */
export type PointUpdatedPayload = {
  pointWallet: Maybe<PointWallet>;
};

export type PointWallet = {
  /** 利用可能なポイントチャージ一覧 */
  availablePointCharges: AvailablePointChargeConnection;
  /** 最も近い引き出し可能期限 */
  nearestAvailableWithdrawableUntilAt: Maybe<
    Scalars['ISO8601DateTime']['output']
  >;
  /** ポイント残高 */
  point: Scalars['Int']['output'];
  /** ポイント交換情報 */
  pointExchange: PointExchange;
  /** ポイントログ一覧 */
  pointLogs: PointLogConnection;
  /** ポイント更新が必要かどうか */
  requirePointRefresh: Scalars['Boolean']['output'];
};

export type PointWalletAvailablePointChargesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PointWalletPointExchangeArgs = {
  requestUid: Scalars['String']['input'];
};

export type PointWalletPointLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  logTypeEq?: InputMaybe<PointWalletType_PointLogs_LogTypeEnumType>;
  loggedAtGteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  loggedAtLteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  statusIn?: InputMaybe<Array<PointLogType_StatusEnumType>>;
};

export enum PointWalletType_PointLogs_LogTypeEnumType {
  /** ポイントチャージ */
  PointCharge = 'point_charge',
  /** ポイント交換 */
  PointExchange = 'point_exchange',
  /** ポイント期限切れ */
  PointExpired = 'point_expired',
}

export type Prefecture = {
  /** 都道府県コード */
  code: Scalars['Int']['output'];
  /** 都道府県名 */
  name: Scalars['String']['output'];
};

export type Promoter = {
  /** プロモーター画像URL */
  imageUrl: Scalars['String']['output'];
  /** プロモーター名 */
  name: Scalars['String']['output'];
};

export type Promotion = {
  /** プロモーション詳細 */
  description: Scalars['String']['output'];
  /** プロモーション名 */
  name: Scalars['String']['output'];
  /** プロモーター情報 */
  promoter: Promoter;
  /** プロモーションタグ */
  promotionTag: PromotionTag;
  /** 報酬情報 */
  reward: Reward;
};

export type PromotionSpot = {
  /** スポット名 */
  name: Scalars['String']['output'];
  /** 表示順 */
  order: Scalars['Int']['output'];
  /** プロモーション公開情報 */
  promotionPublishment: Publishment;
};

export enum PromotionSpotNameEnum {
  GetGift = 'get_gift',
  GiftSaved = 'gift_saved',
}

export type PromotionTag = {
  /** プロモーションタグ名 */
  name: Scalars['String']['output'];
};

export type Publishment = {
  /** プロモーション情報 */
  promotion: Promotion;
  /** URL */
  url: Scalars['String']['output'];
};

export type Query = {
  /** ブランド詳細 */
  brand: Brand;
  /** ブランド一覧 */
  brands: BrandConnection;
  currentUser: User;
  giftConvertToPointRequest: GiftConvertToPointRequest;
  gifteeContent: GifteeContent;
  /** 商品詳細 */
  item: Item;
  /** 商品一覧 */
  items: ItemConnection;
  myGift: MyGift;
  myGiftsConnection: MyGiftConnection;
  pointFeatureGroups: PointFeatureGroupConnection;
  pointWallet: PointWallet;
  promotionSpots: Array<PromotionSpot>;
  /** おすすめ商品一覧 */
  recommendedItems: ItemConnection;
  userAnnouncement: UserAnnouncement;
  userAnnouncements: UserAnnouncementConnection;
};

export type QueryBrandArgs = {
  uid: Scalars['String']['input'];
};

export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recommendEq?: InputMaybe<Scalars['Boolean']['input']>;
  sortConditions?: InputMaybe<Array<BrandType_SortConditionInputType>>;
  withPointsGteq?: InputMaybe<Scalars['Int']['input']>;
  withPointsLteq?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGiftConvertToPointRequestArgs = {
  requestUid: Scalars['String']['input'];
};

export type QueryGifteeContentArgs = {
  url?: InputMaybe<Scalars['String']['input']>;
  urlCode?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryItemArgs = {
  uid: Scalars['String']['input'];
};

export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandUidEq?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortConditions?: InputMaybe<Array<ItemType_SortConditionInputType>>;
  withPointsGteq?: InputMaybe<Scalars['Int']['input']>;
  withPointsLteq?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMyGiftArgs = {
  urlCode: Scalars['ID']['input'];
};

export type QueryMyGiftsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  expiredAtGteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  expiredAtLteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pointMergeableEq?: InputMaybe<Scalars['Boolean']['input']>;
  sortConditions?: InputMaybe<Array<MyGiftSortConditionInputType>>;
  usageStatusIn?: InputMaybe<Array<MyGiftUsageStatusEnum>>;
};

export type QueryPointFeatureGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPromotionSpotsArgs = {
  name: PromotionSpotNameEnum;
};

export type QueryRecommendedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserAnnouncementArgs = {
  uid: Scalars['String']['input'];
};

export type QueryUserAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryUidIn?: InputMaybe<Array<UserAnnouncementTypeCategoryUidEnumType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  titleCont?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ReadUserAnnouncement */
export type ReadUserAnnouncementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAnnouncementUid: Scalars['ID']['input'];
};

/** Autogenerated return type of ReadUserAnnouncement. */
export type ReadUserAnnouncementPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  user: User;
  userAnnouncement: Maybe<UserAnnouncement>;
};

/** Autogenerated input type of ReadUserAnnouncements */
export type ReadUserAnnouncementsInput = {
  categoryUid?: InputMaybe<UserAnnouncementTypeCategoryUidEnumType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReadUserAnnouncements. */
export type ReadUserAnnouncementsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  user: User;
};

/** Autogenerated input type of RefreshMyGift */
export type RefreshMyGiftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  myGiftUrlCode: Scalars['ID']['input'];
};

/** Autogenerated return type of RefreshMyGift. */
export type RefreshMyGiftPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  myGift: Maybe<MyGift>;
};

export type Reward = {
  /** 報酬画像URL */
  imageUrl: Scalars['String']['output'];
  /** 報酬名 */
  name: Scalars['String']['output'];
};

/** Autogenerated input type of SaveGifteeContent */
export type SaveGifteeContentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notifyTarget?: InputMaybe<Scalars['Boolean']['input']>;
  origin: OriginEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SaveGifteeContent. */
export type SaveGifteeContentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  gifteeContent: Maybe<GifteeContent>;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Subscription = {
  /** ギフトポイント変換完了通知 */
  giftConvertToPointCompletion: GiftConvertToPointCompletionPayload;
  /** ポイント更新通知 */
  pointUpdated: PointUpdatedPayload;
};

export type SubscriptionGiftConvertToPointCompletionArgs = {
  requestUid: Scalars['String']['input'];
};

export type User = {
  /** 最終サインイン日時 */
  lastSignInAt: Scalars['ISO8601DateTime']['output'];
  /** ユーザープロフィール */
  profile: Maybe<UserProfileType>;
  /** ユーザーID */
  uid: Scalars['String']['output'];
  /** 未読お知らせ数 */
  unreadAnnouncementsCount: Scalars['Int']['output'];
  /** カテゴリー別未読お知らせ数 */
  unreadAnnouncementsCountByCategoryUid: UserUnreadUserAnnouncementsCountByCategoryUidType;
};

export type UserAnnouncement = {
  /** 本文 */
  body: Scalars['String']['output'];
  /** カテゴリーID */
  categoryUid: UserAnnouncementTypeCategoryUidEnumType;
  /** コンテンツ情報 */
  content: Maybe<Content>;
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 既読日時 */
  readAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイトル */
  title: Scalars['String']['output'];
  /** ユニークID */
  uid: Scalars['String']['output'];
};

/** The connection type for UserAnnouncement. */
export type UserAnnouncementConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserAnnouncementEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserAnnouncement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserAnnouncementEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserAnnouncement>;
};

export enum UserAnnouncementTypeCategoryUidEnumType {
  /** 全体向け */
  ForAll = 'for_all',
  /** あなた向け */
  ForYou = 'for_you',
}

export type UserProfileType = {
  /** 誕生日 */
  birthday: Scalars['ISO8601Date']['output'];
  /** 性別情報 */
  gender: Gender;
  /** 都道府県情報 */
  prefecture: Prefecture;
};

export type UserUnreadUserAnnouncementsCountByCategoryUidType = {
  /** 全体向けお知らせの未読数 */
  forAll: Scalars['Int']['output'];
  /** あなた向けお知らせの未読数 */
  forYou: Scalars['Int']['output'];
};

export type Variable = {
  /** 最大ポイント */
  maxPoint: Scalars['Int']['output'];
  /** 最小ポイント */
  minPoint: Scalars['Int']['output'];
  /** ステップ */
  step: Scalars['Int']['output'];
};

export type ErrorsFieldsFragment = {
  message: string;
  path: Array<string>;
  extensions: { status: number; code: ErrorCodeEnumType | null };
};

export type GifteeContentFieldsFragment = {
  urlCode: string;
  myGifts: Array<{
    urlCode: string;
    contentName: string;
    imageUrl: string;
    giftUrl: string;
    giftType: GiftTypesEnum;
    pointMergeable: boolean;
    remainingPoint: number;
    savedAt: string;
    changedAt: string;
    discardedAt: string | null;
    expiredAt: string | null;
    unavailableAt: string | null;
  }>;
};

export type MyGiftFieldsFragment = {
  urlCode: string;
  contentName: string;
  imageUrl: string;
  giftUrl: string;
  giftType: GiftTypesEnum;
  pointMergeable: boolean;
  remainingPoint: number;
  savedAt: string;
  changedAt: string;
  discardedAt: string | null;
  expiredAt: string | null;
  unavailableAt: string | null;
};

export type PointExchangeFieldsFragment = {
  pointAmount: number;
  item: {
    uid: string;
    contentName: string;
    brand: { uid: string; name: string };
  };
  myGifts: Array<{
    contentName: string;
    expiredAt: string | null;
    giftUrl: string;
    imageUrl: string;
  }>;
};

export type PointWalletFieldsFragment = {
  point: number;
  nearestAvailableWithdrawableUntilAt: string | null;
  requirePointRefresh: boolean;
};

export type UserFieldsFragment = {
  uid: string;
  lastSignInAt: string;
  profile: {
    birthday: string;
    gender: { code: number; name: string };
    prefecture: { code: number; name: string };
  } | null;
  unreadAnnouncementsCountByCategoryUid: { forAll: number; forYou: number };
};

export type CreateGiftConvertToPointMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  myGiftUrlCode: Scalars['String']['input'];
  requestUid: Scalars['String']['input'];
  convertPointAmount: Scalars['Int']['input'];
}>;

export type CreateGiftConvertToPointMutation = {
  createGiftConvertToPointRequest: {
    clientMutationId: string | null;
    giftConvertToPointRequest: {
      convertPointAmount: number;
      requestUid: string;
      pointWallet: {
        point: number;
        nearestAvailableWithdrawableUntilAt: string | null;
        requirePointRefresh: boolean;
      };
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type CreatePointChargeWithSerialCodeMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serialCode: Scalars['String']['input'];
}>;

export type CreatePointChargeWithSerialCodeMutation = {
  createPointChargeWithSerialCode: {
    clientMutationId: string | null;
    pointCharge: { pointAmount: number; withdrawableUntilAt: string } | null;
    pointWallet: {
      point: number;
      nearestAvailableWithdrawableUntilAt: string | null;
      requirePointRefresh: boolean;
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type CreatePointExchangeMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  pointAmount: Scalars['Int']['input'];
  requestUid: Scalars['String']['input'];
  itemUid: Scalars['String']['input'];
  pointBackCampaignUid?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreatePointExchangeMutation = {
  createPointExchange: {
    pointExchange: {
      pointAmount: number;
      pointLog: { status: PointLogType_StatusEnumType };
      pointBackCampaignApply: {
        backPoint: number;
        pointBackCampaign: { discountPercentage: number };
      } | null;
      item: {
        uid: string;
        contentName: string;
        brand: { uid: string; name: string };
      };
      myGifts: Array<{
        contentName: string;
        expiredAt: string | null;
        giftUrl: string;
        imageUrl: string;
      }>;
    } | null;
    pointWallet: {
      point: number;
      nearestAvailableWithdrawableUntilAt: string | null;
      requirePointRefresh: boolean;
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type CreateUserProfileMutationVariables = Exact<{
  input: CreateUserProfileInput;
}>;

export type CreateUserProfileMutation = {
  createUserProfile: {
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type DiscardMyGiftsMutationVariables = Exact<{
  myGiftUrlCodes: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type DiscardMyGiftsMutation = {
  discardMyGifts: {
    myGifts: Array<{
      urlCode: string;
      contentName: string;
      imageUrl: string;
      giftUrl: string;
      giftType: GiftTypesEnum;
      pointMergeable: boolean;
      remainingPoint: number;
      savedAt: string;
      changedAt: string;
      discardedAt: string | null;
      expiredAt: string | null;
      unavailableAt: string | null;
    }>;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type ReadAnnouncementMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAnnouncementUid: Scalars['ID']['input'];
}>;

export type ReadAnnouncementMutation = {
  readUserAnnouncement: {
    user: {
      uid: string;
      lastSignInAt: string;
      profile: {
        birthday: string;
        gender: { code: number; name: string };
        prefecture: { code: number; name: string };
      } | null;
      unreadAnnouncementsCountByCategoryUid: { forAll: number; forYou: number };
    };
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type ReadAnnouncementsMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  categoryUid?: InputMaybe<UserAnnouncementTypeCategoryUidEnumType>;
}>;

export type ReadAnnouncementsMutation = {
  readUserAnnouncements: {
    user: {
      uid: string;
      lastSignInAt: string;
      profile: {
        birthday: string;
        gender: { code: number; name: string };
        prefecture: { code: number; name: string };
      } | null;
      unreadAnnouncementsCountByCategoryUid: { forAll: number; forYou: number };
    };
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type RefreshMyGiftMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  urlCode: Scalars['ID']['input'];
}>;

export type RefreshMyGiftMutation = {
  refreshMyGift: {
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
    myGift: {
      urlCode: string;
      contentName: string;
      imageUrl: string;
      giftUrl: string;
      giftType: GiftTypesEnum;
      pointMergeable: boolean;
      remainingPoint: number;
      savedAt: string;
      changedAt: string;
      discardedAt: string | null;
      expiredAt: string | null;
      unavailableAt: string | null;
    } | null;
  };
};

export type SaveGifteeContentMutationVariables = Exact<{
  url: Scalars['String']['input'];
  origin: OriginEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
  notifyTarget?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SaveGifteeContentMutation = {
  saveGifteeContent: {
    gifteeContent: {
      urlCode: string;
      myGifts: Array<{
        urlCode: string;
        contentName: string;
        imageUrl: string;
        giftUrl: string;
        giftType: GiftTypesEnum;
        pointMergeable: boolean;
        remainingPoint: number;
        savedAt: string;
        changedAt: string;
        discardedAt: string | null;
        expiredAt: string | null;
        unavailableAt: string | null;
      }>;
    } | null;
    errors: Array<{
      message: string;
      path: Array<string>;
      extensions: { status: number; code: ErrorCodeEnumType | null };
    }>;
  };
};

export type GetAnnouncementQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetAnnouncementQuery = {
  userAnnouncement: {
    uid: string;
    title: string;
    body: string;
    createdAt: string;
    readAt: string | null;
    content: { imageFileUrl: string; linkUrl: string | null } | null;
  };
};

export type GetAnnouncementsConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  categoryUidIn?: InputMaybe<
    | Array<UserAnnouncementTypeCategoryUidEnumType>
    | UserAnnouncementTypeCategoryUidEnumType
  >;
}>;

export type GetAnnouncementsConnectionQuery = {
  userAnnouncements: {
    nodes: Array<{
      uid: string;
      title: string;
      createdAt: string;
      readAt: string | null;
    } | null> | null;
    pageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type GetAvailablePointChargesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAvailablePointChargesQuery = {
  pointWallet: {
    availablePointCharges: {
      nodes: Array<{
        uid: string;
        pointAmount: number;
        remainingAmount: number;
        withdrawableUntilAt: string;
      } | null> | null;
      pageInfo: { endCursor: string | null; hasNextPage: boolean };
    };
  };
};

export type GetBrandQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetBrandQuery = {
  brand: {
    description: string;
    iconUrl: string;
    name: string;
    uid: string;
    currentPointBackCampaign: {
      beginAt: string;
      description: string;
      discountPercentage: number;
      discountRate: number;
      endAt: string;
      normalDiscountPercentage: number | null;
      normalDiscountRate: number | null;
      uid: string;
    } | null;
  };
};

export type GetBrandsConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  withPointsGteq?: InputMaybe<Scalars['Int']['input']>;
  withPointsLteq?: InputMaybe<Scalars['Int']['input']>;
  recommendEq?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetBrandsConnectionQuery = {
  brands: {
    nodes: Array<{
      uid: string;
      name: string;
      iconUrl: string;
      description: string;
      minPoint: number | null;
      currentPointBackCampaign: {
        discountPercentage: number;
        normalDiscountPercentage: number | null;
        description: string;
        limited: boolean;
      } | null;
    } | null> | null;
    pageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  currentUser: {
    uid: string;
    lastSignInAt: string;
    profile: {
      birthday: string;
      gender: { code: number; name: string };
      prefecture: { code: number; name: string };
    } | null;
    unreadAnnouncementsCountByCategoryUid: { forAll: number; forYou: number };
  };
};

export type GetGiftConvertToPointRequestQueryVariables = Exact<{
  requestUid: Scalars['String']['input'];
}>;

export type GetGiftConvertToPointRequestQuery = {
  giftConvertToPointRequest: {
    convertPointAmount: number;
    errorMessage: string | null;
    requestUid: string;
    myGift: { pointMergeable: boolean };
  };
};

export type GetGifteeContentQueryVariables = Exact<{
  urlCode?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetGifteeContentQuery = {
  gifteeContent: {
    urlCode: string;
    myGifts: Array<{
      urlCode: string;
      contentName: string;
      imageUrl: string;
      giftUrl: string;
      giftType: GiftTypesEnum;
      pointMergeable: boolean;
      remainingPoint: number;
      savedAt: string;
      changedAt: string;
      discardedAt: string | null;
      expiredAt: string | null;
      unavailableAt: string | null;
    }>;
  };
};

export type GetItemDetailQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetItemDetailQuery = {
  item: {
    caution: string;
    contentImageUrl: string;
    contentName: string;
    readableExpiration: string;
    description: string;
    uid: string;
    brand: { uid: string; name: string; pointCaution: string };
    currentItemPointPhase: {
      endAt: string;
      point: number;
      startAt: string;
      variable: { maxPoint: number; minPoint: number; step: number } | null;
    } | null;
    currentPointBackCampaign: {
      beginAt: string;
      description: string;
      discountPercentage: number;
      discountRate: number;
      endAt: string;
      normalDiscountPercentage: number | null;
      normalDiscountRate: number | null;
      uid: string;
      limited: boolean;
    } | null;
  };
};

export type GetItemsConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandUidEq?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  withPointsGteq?: InputMaybe<Scalars['Int']['input']>;
  withPointsLteq?: InputMaybe<Scalars['Int']['input']>;
  sortConditions?: InputMaybe<
    Array<ItemType_SortConditionInputType> | ItemType_SortConditionInputType
  >;
}>;

export type GetItemsConnectionQuery = {
  items: {
    nodes: Array<{
      uid: string;
      contentName: string;
      contentImageUrl: string;
      readableExpiration: string;
      brand: { name: string };
      currentItemPointPhase: {
        point: number;
        startAt: string;
        endAt: string;
        variable: { maxPoint: number; minPoint: number; step: number } | null;
      } | null;
      currentPointBackCampaign: {
        beginAt: string;
        description: string;
        discountPercentage: number;
        endAt: string;
        normalDiscountPercentage: number | null;
        uid: string;
        limited: boolean;
      } | null;
    } | null> | null;
    pageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type GetMyGiftQueryVariables = Exact<{
  urlCode: Scalars['ID']['input'];
}>;

export type GetMyGiftQuery = {
  myGift: {
    urlCode: string;
    contentName: string;
    imageUrl: string;
    giftUrl: string;
    giftType: GiftTypesEnum;
    pointMergeable: boolean;
    remainingPoint: number;
    savedAt: string;
    changedAt: string;
    discardedAt: string | null;
    expiredAt: string | null;
    unavailableAt: string | null;
  };
};

export type GetMyGiftsConnectionQueryVariables = Exact<{
  sortConditions?: InputMaybe<
    Array<MyGiftSortConditionInputType> | MyGiftSortConditionInputType
  >;
  usageStatusIn?: InputMaybe<
    Array<MyGiftUsageStatusEnum> | MyGiftUsageStatusEnum
  >;
  expiredAtLteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  expiredAtGteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  pointMergeableEq?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMyGiftsConnectionQuery = {
  myGiftsConnection: {
    nodes: Array<{
      urlCode: string;
      contentName: string;
      imageUrl: string;
      giftUrl: string;
      giftType: GiftTypesEnum;
      pointMergeable: boolean;
      remainingPoint: number;
      savedAt: string;
      changedAt: string;
      discardedAt: string | null;
      expiredAt: string | null;
      unavailableAt: string | null;
    } | null> | null;
    pageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type GetPointFeatureGroupsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPointFeatureGroupsQuery = {
  pointFeatureGroups: {
    nodes: Array<{
      layout: LayoutEnum;
      label: string;
      uid: string;
      pointFeatures: Array<{
        imageUrl: string;
        title: string;
        uid: string;
        destination: { kind: PointFeatureDestinationKindEnum; uid: string };
      }>;
    } | null> | null;
    pageInfo: { endCursor: string | null; hasNextPage: boolean };
  };
};

export type GetPointLogsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<
    Array<PointLogType_StatusEnumType> | PointLogType_StatusEnumType
  >;
  loggedAtGteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  loggedAtLteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  logTypeEq?: InputMaybe<PointWalletType_PointLogs_LogTypeEnumType>;
}>;

export type GetPointLogsQuery = {
  pointWallet: {
    pointLogs: {
      pageInfo: { endCursor: string | null; hasNextPage: boolean };
      nodes: Array<{
        title: string;
        status: PointLogType_StatusEnumType;
        loggedAt: string;
        statement:
          | {
              __typename: 'PointCharge';
              pointAmount: number;
              withdrawableUntilAt: string;
            }
          | {
              __typename: 'PointExchange';
              pointAmount: number;
              myGifts: Array<{ contentName: string; imageUrl: string }>;
            }
          | { __typename: 'PointExpired'; expiredPoint: number };
      } | null> | null;
    };
  };
};

export type GetPointWalletQueryVariables = Exact<{ [key: string]: never }>;

export type GetPointWalletQuery = {
  pointWallet: {
    point: number;
    nearestAvailableWithdrawableUntilAt: string | null;
    requirePointRefresh: boolean;
  };
};

export type GetPromotionSpotsQueryVariables = Exact<{
  name: PromotionSpotNameEnum;
}>;

export type GetPromotionSpotsQuery = {
  promotionSpots: Array<{
    name: string;
    order: number;
    promotionPublishment: {
      url: string;
      promotion: {
        name: string;
        description: string;
        promoter: { imageUrl: string; name: string };
        reward: { imageUrl: string; name: string };
        promotionTag: { name: string };
      };
    };
  }>;
};

export type GiftConvertToPointRequestSubscriptionVariables = Exact<{
  requestUid: Scalars['String']['input'];
}>;

export type GiftConvertToPointRequestSubscription = {
  giftConvertToPointCompletion: {
    giftConvertToPointRequest: {
      convertPointAmount: number;
      errorMessage: string | null;
      requestUid: string;
    } | null;
  };
};

export type PointUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PointUpdatedSubscription = {
  pointUpdated: {
    pointWallet: {
      point: number;
      nearestAvailableWithdrawableUntilAt: string | null;
      requirePointRefresh: boolean;
    } | null;
  };
};
