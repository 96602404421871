import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { Link } from '@mui/material';
import { styled } from '@mui/system';
import { BaseButton } from '../button/baseButton/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';
import { theme } from '/@/utils/theme';

const StyledContainer = styled('div')({
  textAlign: 'center',
});
const StyledNoteContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledConvertToPointNoteContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.grey[700],
  width: '100%',
  textAlign: 'center',
}));
const StyledLoginButtonContainer = styled('div')({
  fontWeight: 'bold',
  width: '100%',
});

type Props = {
  children: string;
  onClick: () => Promise<void> | undefined;
  isDisplayNoteForPointConvert: boolean;
  isDisplayButtonOnly: boolean;
};

const Presenter: React.VFC<Props> = ({
  children,
  onClick,
  isDisplayNoteForPointConvert,
  isDisplayButtonOnly,
}) => (
  <StyledContainer>
    {isDisplayButtonOnly === false && (
      <StyledNoteContainer>
        <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
          <Link
            component={RouterLink}
            to={PATHS.TERMS_OF_SERVICE}
            style={{
              color: theme.palette.secondary.dark,
              textDecorationColor: theme.palette.secondary.dark,
            }}
          >
            サービス利用規約
          </Link>
          に同意したことになります
        </BaseTypography>
        {isDisplayNoteForPointConvert && (
          <StyledConvertToPointNoteContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
              ※ポイントは残高全てが移行されます。ご注意ください。
            </BaseTypography>
          </StyledConvertToPointNoteContainer>
        )}
      </StyledNoteContainer>
    )}
    <StyledLoginButtonContainer>
      <BaseButton
        onClick={onClick}
        data-gtm-click="shared-loginButton-loginButton"
      >
        {children}
      </BaseButton>
    </StyledLoginButtonContainer>
  </StyledContainer>
);

export { Presenter };
