import React, { ChangeEvent, FormEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  SelectChangeEvent,
  FormLabelProps,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { Header } from '/@/components/templates/header/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { PATHS } from '/@/routes/paths';
import GiftWalletLogo from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';
import { UserProfileNewPageInputState } from '/@/store/page/userPage/profilePage/newPage';
import {
  BaseTypography,
  FONT_SIZE,
  FONT_TYPE,
} from '/@/components/shared/typography/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { theme } from '/@/utils/theme';

const StyledBaseBox = styled(Box)(({ theme }) => ({
  marginTop: `calc(${HEADER_HEIGHT}px + ${theme.spacing(6)})`,
}));
const StyledLogoImage = styled('img')({
  display: 'block',
  width: '200px',
  margin: 'auto',
});
const StyledTitleTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  color: theme.palette.secondary.dark,
  textAlign: 'center',
}));
const StyledCaptionTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.grey[700],
  textAlign: 'center',
}));
const StyledProfileFormContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(4, 2),
}));
const StyledCaptionText = styled(Typography)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  color: theme.palette.grey[700],
}));
const StyledDateText = styled(Typography)(({ theme }) => ({
  marginTop: 'auto',
  color: theme.palette.grey[700],
}));
const StyledFormContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  '& + &': {
    marginTop: theme.spacing(2),
  },
}));
const StyledFormGroup = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(),
}));
const StyledFormTitleLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  color: theme.palette.grey[700],
}));
const StyledBirthDayTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  fieldset: {
    border: 'none',
  },
}));
const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    color: checked ? theme.palette.common.white : theme.palette.secondary.dark,
    backgroundColor: checked
      ? theme.palette.common.black
      : theme.palette.background.default,
    borderRadius: theme.spacing(3),
    width: 'calc((100% - 16px) / 3)',
    maxWidth: '120px',
  }),
);
const StyledSubmitButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(2),
  fontWeight: 'bold',
}));

type Props = {
  genderCode: UserProfileNewPageInputState['genderCode'] | null;
  prefectureCode: UserProfileNewPageInputState['prefectureCode'] | null;
  isLoading: boolean;
  isReadyToSubmit: boolean;
  pushDatalayer: () => void;
  handleChangeGender: (selectedGenderCode: number) => void;
  handleChangeBirthdayYear: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayMonth: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefecture: (event: SelectChangeEvent<number | null>) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const Presenter: React.VFC<Props> = ({
  prefectureCode,
  isLoading,
  isReadyToSubmit,
  pushDatalayer,
  handleChangeGender,
  handleChangeBirthdayYear,
  handleChangeBirthdayMonth,
  handleChangeBirthdayDay,
  handleChangePrefecture,
  handleSubmit,
}) => (
  <>
    <Header isMenuVisible={false} isLogoClickable={false} />
    <Container maxWidth="sm">
      <StyledBaseBox>
        <StyledLogoImage alt="gift wallet logo" src={GiftWalletLogo} />
        <StyledTitleTextContainer>
          <BaseTypography
            fontType={FONT_TYPE.BODY}
            fontSize={FONT_SIZE.MD}
            isBold
          >
            プロフィールを登録してギフトを保存
          </BaseTypography>
        </StyledTitleTextContainer>
        <StyledCaptionTextContainer>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD}>
            誕生日やお住まいの地域など情報を登録すると
            <br />
            あなただけの限定ギフトやクーポンが届きます
          </BaseTypography>
        </StyledCaptionTextContainer>

        <StyledProfileFormContainer>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" alignItems="stretch" spacing={4}>
              <StyledFormContainer>
                <FormControl component="fieldset" fullWidth>
                  <StyledFormTitleLabel component="legend">
                    性別
                  </StyledFormTitleLabel>
                  <Box mt={2}>
                    <RadioGroup row>
                      {genderCodes.map((genderCodeMaster, index) => (
                        <StyledFormControlLabel
                          key={index}
                          value={genderCodeMaster.code}
                          control={<Radio />}
                          label={genderCodeMaster.name}
                          onClick={() =>
                            handleChangeGender(genderCodeMaster.code)
                          }
                          data-gtm-click="userPage-profilePage-newPage-genderSelectionButton"
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </FormControl>
              </StyledFormContainer>
              <StyledFormContainer>
                <FormControl component="fieldset">
                  <StyledFormTitleLabel component="legend">
                    生年月日
                  </StyledFormTitleLabel>
                  <StyledFormGroup
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid size="grow">
                      <StyledBirthDayTextField
                        id="user-profile-birth-year"
                        variant="outlined"
                        size="small"
                        required
                        value={null}
                        autoComplete="bday-year"
                        onChange={handleChangeBirthdayYear}
                        slotProps={{
                          htmlInput: {
                            inputMode: 'numeric',
                            style: { textAlign: 'center' },
                            maxLength: 4,
                            minLength: 4,
                            pattern: '^(19|20)\\d{2}$',
                          },
                        }}
                      />
                    </Grid>
                    <StyledDateText variant="caption">年</StyledDateText>
                    <Grid size="grow">
                      <StyledBirthDayTextField
                        id="user-profile-birth-month"
                        variant="outlined"
                        size="small"
                        required
                        value={null}
                        autoComplete="bday-month"
                        onChange={handleChangeBirthdayMonth}
                        slotProps={{
                          htmlInput: {
                            inputMode: 'numeric',
                            style: { textAlign: 'center' },
                            maxLength: 2,
                            minLength: 1,
                            pattern: '^(0?[1-9]|1[012])$',
                          },
                        }}
                      />
                    </Grid>
                    <StyledDateText variant="caption">月</StyledDateText>
                    <Grid size="grow">
                      <StyledBirthDayTextField
                        id="user-profile-birth-day"
                        variant="outlined"
                        size="small"
                        required
                        value={null}
                        autoComplete="bday-day"
                        onChange={handleChangeBirthdayDay}
                        slotProps={{
                          htmlInput: {
                            inputMode: 'numeric',
                            style: { textAlign: 'center' },
                            maxLength: 2,
                            minLength: 1,
                            pattern: '^(0?[1-9]|[12]\\d|3[01])$',
                          },
                        }}
                      />
                    </Grid>
                    <StyledDateText variant="caption">日</StyledDateText>
                  </StyledFormGroup>
                </FormControl>
              </StyledFormContainer>
              <StyledFormContainer>
                <FormControl fullWidth>
                  <StyledFormTitleLabel id="user-profile-prefecture-label">
                    お住まいの地域
                  </StyledFormTitleLabel>
                  <Select
                    required
                    labelId="user-profile-prefecture-label"
                    id="user-profile-prefecture"
                    value={prefectureCode}
                    variant="standard"
                    onChange={handleChangePrefecture}
                  >
                    {prefectureCodes.map((prefectureCodeMaster) => (
                      <MenuItem
                        key={prefectureCodeMaster.code}
                        value={prefectureCodeMaster.code}
                      >
                        {prefectureCodeMaster.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledFormContainer>
              <Grid size="grow">
                <StyledCaptionText variant="caption" gutterBottom>
                  <Link
                    component={RouterLink}
                    to={PATHS.TERMS_OF_SERVICE}
                    style={{
                      color: theme.palette.secondary.dark,
                      textDecoration: 'none',
                    }}
                  >
                    サービス利用規約
                  </Link>
                  に同意したことになります
                </StyledCaptionText>
                <StyledSubmitButton
                  disableElevation
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!isReadyToSubmit}
                  loading={isLoading}
                  onClick={pushDatalayer}
                >
                  登録
                </StyledSubmitButton>
              </Grid>
            </Grid>
          </form>
        </StyledProfileFormContainer>
      </StyledBaseBox>
    </Container>
    <Footer />
  </>
);

export { Presenter };
