import { gql } from '@apollo/client';

export const getFrequentlyAskedQuestionGql = gql`
  query GetFrequentlyAskedQuestionsGql($serviceName: ServiceNameEnum!) {
    frequentlyAskedQuestions(serviceName: $serviceName) {
      title
      answer
    }
  }
`;
