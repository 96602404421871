import React from 'react';
import { Grid, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { MyGiftBase } from '/@/store/app/shared/types';
import expiredAtLabel from '/@/utils/expiredAtLabel';
import { formatToEndDateJa } from '/@/utils/formatDate';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledCard = styled(Card)(({ theme }) => ({
  border: 'none',
  borderRadius: theme.spacing(2),
}));
const StyledContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  marginTop: 'auto',
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '2px solid ' + theme.palette.secondary.light,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: `calc(100% - ${theme.spacing(8)} - ${theme.spacing(1)})`, // MEMO: 左のコンテンツ幅とマージン分を引く
}));
const StyledItemNameContainer = styled('div')({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
});
const StyledItemExpiredAtContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.grey[700],
}));

type Props = {
  myGifts: MyGiftBase[];
};

const Presenter: React.VFC<Props> = ({ myGifts }) => (
  <StyledCard variant="outlined">
    <StyledContent>
      {myGifts.map((myGift, index) => (
        <div key={index}>
          {index > 0 && <StyledDivider />}
          <StyledLogoAndTitle>
            <StyledLogoContainer>
              <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
            </StyledLogoContainer>
            <StyledRightContent>
              <StyledItemNameContainer>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.MD}
                  isBold
                >
                  {myGift.contentName}
                </BaseTypography>
              </StyledItemNameContainer>
              <StyledItemExpiredAtContainer>
                <BaseTypography
                  fontType={FONT_TYPE.BODY}
                  fontSize={FONT_SIZE.SM}
                >
                  {expiredAtLabel(myGift.giftType) +
                    '期限 ' +
                    (myGift.expiredAt !== null
                      ? formatToEndDateJa(myGift.expiredAt)
                      : '…取得中')}
                </BaseTypography>
              </StyledItemExpiredAtContainer>
            </StyledRightContent>
          </StyledLogoAndTitle>
        </div>
      ))}
    </StyledContent>
  </StyledCard>
);

export { Presenter };
