import React from 'react';
import { Grid, styled } from '@mui/material';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import type { PointLogWithDetail } from '/@/store/page/pointLogsPage/listPage/selectors';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledPointLogContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
const StyledDividerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0),
}));

type OwnProps = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  pointLogsWithDetail: PointLogWithDetail[];
  getPointLogsStatus: Status;
};

const Presenter: React.VFC<OwnProps> = ({
  infiniteScrollTarget,
  pointLogsWithDetail,
  getPointLogsStatus,
}) => (
  <Grid>
    {getPointLogsStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <StyledPointLogContainer>
        {pointLogsWithDetail.map((pointLogWithDetail, index) => (
          <Grid key={index}>
            <PointLog
              title={pointLogWithDetail.title}
              details={pointLogWithDetail.details}
              point={pointLogWithDetail.point}
            />
            {index !== pointLogsWithDetail.length - 1 && (
              <StyledDividerContainer>
                <BaseDivider />
              </StyledDividerContainer>
            )}
          </Grid>
        ))}
      </StyledPointLogContainer>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
