import React from 'react';
import { GifteeContent as GifteeContentProps } from './Index';
import { GifteeContent } from './gifteeContent/Index';
import { BaseButton } from '../button/baseButton/Index';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { styled } from '@mui/material';

const StyledNavigationHomeButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
}));

type Props = {
  gifteeContent: GifteeContentProps;
};
const Presenter: React.VFC<Props> = ({ gifteeContent }) => {
  if (gifteeContent.myGifts?.length) {
    return (
      <div>
        <GifteeContent myGifts={gifteeContent.myGifts} />
        <StyledNavigationHomeButton>
          <BaseButton component={Link} to={PATHS.HOME}>
            マイギフトを確認する
          </BaseButton>
        </StyledNavigationHomeButton>
      </div>
    );
  }
  return <></>;
};

export { Presenter };
