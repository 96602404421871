import React from 'react';
import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ItemBase } from '/@/store/app/shared/types';
import { Points } from '../../points/Index';
import '/@/assets/font/font.css';
import ArrowRightIcon from '/@/assets/shared/icon/neo-arrow-right-icon.svg';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';
import { LimitedLabel } from '/@/components/shared/label/limitedLabel/Index';
import { LABEL_SIZE } from '/@/components/shared/label/baseLabel/Presenter';
import { BaseDivider } from '/@/components/shared/divider/Index';

const StyledItemContainer = styled('div')({
  width: '100%',
});
type StyledItemContainerProps = {
  isExchangeable: boolean;
};
const StyledItemCardContainer = styled(Card)<StyledItemContainerProps>(
  ({ isExchangeable, theme }) => ({
    position: 'relative',
    marginTop: theme.spacing(2),
    padding: 0,
    borderRadius: theme.spacing(1.5),
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    ...(!isExchangeable && {
      backgroundColor: theme.palette.common.white,
      opacity: 0.3,
    }),
  }),
);
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(9),
  width: theme.spacing(9),
  height: theme.spacing(9),
  marginTop: 0,
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
  overflow: 'hidden',
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  width: `calc(100% - ${theme.spacing(9)} - ${theme.spacing(1.5)})`, // MEMO: 左のコンテンツ幅とマージン分を引く
}));
const StyledItemName = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
});
const StyledEndAt = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: 0,
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  color: theme.palette.grey.A700,
}));
const StyledLabels = styled('div')({
  '& > * + *': {
    marginLeft: '0.25rem',
  },
});
const StyledPointDisplayArea = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));
const StyledPointText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '1rem',
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
}));
const StyledPoints = styled('span')({
  fontFamily: 'Grandstander-Bold',
  fontSize: '1.5rem',
  lineHeight: '1',
  fontWeight: 400,
});
const StyledRightAllow = styled('div')({
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
});

type Props = {
  exchangeable: boolean;
  itemContent: ItemBase;
  selectItemDetail: (uid: string) => void;
};

const Presenter: React.VFC<Props> = ({
  exchangeable,
  itemContent,
  selectItemDetail,
}) => (
  <StyledItemContainer>
    <BaseDivider />
    <StyledItemCardContainer
      isExchangeable={exchangeable}
      variant="outlined"
      onClick={() => exchangeable && selectItemDetail(itemContent.uid)}
      data-gtm-click="shared-pointItemContents-itemCard-card"
    >
      <StyledLogoAndTitle>
        <StyledLogoContainer>
          <StyledLogo
            src={itemContent.contentImageUrl}
            alt={itemContent.contentName}
          />
        </StyledLogoContainer>
        <StyledRightContent>
          <StyledItemName>{itemContent.contentName}</StyledItemName>
          {itemContent.currentItemPointPhase && (
            <>
              <StyledEndAt>
                {'有効期限 ' + itemContent.readableExpiration}
              </StyledEndAt>
              <StyledPointDisplayArea>
                {itemContent.currentItemPointPhase.variable ? (
                  <StyledPointText>
                    <StyledPoints>
                      {itemContent.currentItemPointPhase.variable.minPoint.toLocaleString(
                        'ja-JP',
                      )}
                      {' ~ '}
                      {itemContent.currentItemPointPhase.variable.maxPoint.toLocaleString(
                        'ja-JP',
                      )}
                    </StyledPoints>
                    ポイント
                  </StyledPointText>
                ) : (
                  <Points points={itemContent.currentItemPointPhase.point} />
                )}
              </StyledPointDisplayArea>
              <StyledLabels>
                {itemContent.currentPointBackCampaign && (
                  <DiscountPercentageLabel
                    discountPercentage={
                      itemContent.currentPointBackCampaign.discountPercentage
                    }
                    labelSize={LABEL_SIZE.MEDIUM}
                  />
                )}
                {itemContent.currentPointBackCampaign?.limited && (
                  <LimitedLabel labelSize={LABEL_SIZE.MEDIUM} />
                )}
              </StyledLabels>
            </>
          )}
        </StyledRightContent>
      </StyledLogoAndTitle>
      <StyledRightAllow>
        <img src={ArrowRightIcon} alt="商品ページへ進む" />
      </StyledRightAllow>
    </StyledItemCardContainer>
  </StyledItemContainer>
);

export { Presenter };
export type { Props as PresenterProps };
