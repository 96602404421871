import React from 'react';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';

type Props = LoginOption;

const Presenter: React.VFC<Props> = ({
  convertToPoint,
  destination,
  serialCode,
}) => {
  const buttonText = serialCode
    ? 'ログインしてポイントをチャージ'
    : 'さっそく使ってみる';

  return (
    <div data-testid="signInPageSummarySection">
      <div>
        <LoginButton
          url={null}
          origin={null}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        >
          {buttonText}
        </LoginButton>
      </div>
    </div>
  );
};

export { Presenter };
