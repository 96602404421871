import React from 'react';
import { Fade } from '@mui/material';

type Props = {
  children: React.ReactElement;
};

const TIMEOUT_ENTER = 500;

const PageFadeIn: React.VFC<Props> = ({ children }) => (
  <Fade in timeout={{ enter: TIMEOUT_ENTER }} unmountOnExit>
    <div>{children}</div>
  </Fade>
);

export { PageFadeIn, TIMEOUT_ENTER };
