import React from 'react';
import {
  AppBar,
  SwipeableDrawer,
  List,
  ListItemText,
  ListSubheader,
  ListItemButton,
  Grid,
  ListItemButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { HeaderTitle } from '../headerComponents/headerTitle/Index';
import { HeaderLogo } from '../headerComponents/headerLogo/Index';
import { HeaderGrobalMenu } from '../headerComponents/headerGrobalMenu/Index';
import { CurrentUser } from '/@/store/app/currentUser/reducers';
import { UnreadBadge } from '/@/components/shared/badge/unreadBadge/Index';
import logo from '/@/assets/shared/logo/gift-wallet-logo-with-title.svg';
import closeIcon from '/@/assets/shared/icon/close-icon.svg';
import { BaseDivider } from '/@/components/shared/divider/Index';
import ArrowRightIcon from '/@/assets/shared/icon/neo-arrow-right-icon.svg';

export const HEADER_HEIGHT = 48;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 'none',
}));
const StyledTitleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  height: `${HEADER_HEIGHT}px`,
  minHeight: `${HEADER_HEIGHT}px`,
}));

const StyledMenuDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    height: '100%',
    maxWidth: '400px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '87.5%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
}));
const StyledMenuHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
const StyledLogoContainer = styled('div')({});
const StyledCloseIconContainer = styled('div')({});
const StyledMenuListSubheader = styled(ListSubheader)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  padding: '0',
  fontSize: '0.75rem',
  color: theme.palette.grey[700],
  lineHeight: '1.5',
}));
const StyledMenuListItemText = styled(ListItemText)(({ theme }) => ({
  flex: 'none',
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    color: theme.palette.secondary.dark,
  },
}));
const StyledArrowRightIcon = styled('img')({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});
type StyledListItemButtonProps = ListItemButtonProps & { to: string };
const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(
  ({ theme }) => ({
    padding: theme.spacing(2, 0),
  }),
);
const StyledUnreadBadgeContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

type Props = {
  headerTitle?: string;
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
  handleNavigateBack: () => void;
  unreadAnnouncementsCount:
    | CurrentUser['unreadAnnouncementsCountByCategoryUid']
    | undefined;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
  handleClickGlobalMenu,
  handleNavigateBack,
  unreadAnnouncementsCount,
}) => (
  <>
    <StyledAppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      color="default"
    >
      <StyledTitleGrid container alignItems="center">
        {headerTitle ? (
          <HeaderTitle
            headerTitle={headerTitle}
            handleNavigateBack={handleNavigateBack}
          />
        ) : (
          <HeaderLogo isLogoClickable={isLogoClickable} />
        )}
        {isMenuVisible && (
          <HeaderGrobalMenu
            unreadBadgeVisible={
              !!unreadAnnouncementsCount &&
              (unreadAnnouncementsCount?.forYou > 0 ||
                unreadAnnouncementsCount?.forAll > 0)
            }
            handleToggleMenu={handleToggleMenu}
            handleClickGlobalMenu={handleClickGlobalMenu}
          />
        )}
      </StyledTitleGrid>
    </StyledAppBar>

    <StyledMenuDrawer
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <StyledMenuHeaderContainer>
        <StyledLogoContainer>
          <img src={logo} alt="gift wallet logo" width="100%" height="26" />
        </StyledLogoContainer>
        <StyledCloseIconContainer onClick={handleToggleMenu}>
          <img src={closeIcon} alt="close icon" width="28" height="28" />
        </StyledCloseIconContainer>
      </StyledMenuHeaderContainer>
      <List component="nav" aria-label="menu-list">
        <StyledListItemButton
          data-cy="listItemHome"
          component={RouterLink}
          to={PATHS.HOME}
        >
          <StyledMenuListItemText primary="ホーム" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>

        <BaseDivider />

        <StyledMenuListSubheader>お知らせ</StyledMenuListSubheader>
        <BaseDivider />
        <StyledListItemButton
          data-cy="announcement"
          component={RouterLink}
          to={PATHS.ANNOUNCEMENTS_FOR_YOU}
        >
          <StyledMenuListItemText primary="あなた宛" />
          {unreadAnnouncementsCount && unreadAnnouncementsCount?.forYou > 0 && (
            <StyledUnreadBadgeContainer>
              <UnreadBadge />
            </StyledUnreadBadgeContainer>
          )}
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>
        <BaseDivider />
        <StyledListItemButton
          data-cy="announcement"
          component={RouterLink}
          to={PATHS.ANNOUNCEMENTS_FOR_ALL}
        >
          <StyledMenuListItemText primary="ニュース" />
          {unreadAnnouncementsCount && unreadAnnouncementsCount?.forAll > 0 && (
            <StyledUnreadBadgeContainer>
              <UnreadBadge />
            </StyledUnreadBadgeContainer>
          )}
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>

        <BaseDivider />

        <StyledMenuListSubheader>ギフト管理</StyledMenuListSubheader>
        <BaseDivider />
        <StyledListItemButton
          data-cy="listItemHome"
          component={RouterLink}
          to={PATHS.HOME}
        >
          <StyledMenuListItemText primary="マイギフト" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>
        <BaseDivider />
        <StyledListItemButton component={RouterLink} to={PATHS.PROMOTIONS}>
          <StyledMenuListItemText primary="もらえるギフト" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>

        <BaseDivider />

        <StyledMenuListSubheader>ヘルプ</StyledMenuListSubheader>
        <BaseDivider />
        <StyledListItemButton
          component={RouterLink}
          to={PATHS.FREQUENTLY_ASKED_QUESTIONS}
        >
          <StyledMenuListItemText primary="よくあるご質問" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>
        <BaseDivider />
        <StyledListItemButton component={RouterLink} to={PATHS.INQUIRIES_NEW}>
          <StyledMenuListItemText primary="お問い合わせ" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>
        <BaseDivider />
        <StyledListItemButton
          component={RouterLink}
          to={PATHS.TERMS_OF_SERVICE}
        >
          <StyledMenuListItemText primary="サービス利用規約" />
          <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
        </StyledListItemButton>
        <BaseDivider />
      </List>
    </StyledMenuDrawer>
  </>
);

export { Presenter };
